@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../ingredients/styles/focus';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.footer {
  background-color: $colour-white;
  color: $colour-primary-grey;
  line-height: 1.43;
  margin-top: auto;
  padding: {
    bottom: $grid-vertical-gutter * 2;
    top: $grid-vertical-gutter * 2;
  }

  @include media-breakpoint-up('md') {
    background-color: $colour-white;
    display: flex;
    align-items: center;
    height: 54px;
    justify-content: space-between;
    padding: {
      bottom: 0;
      top: 0;
    }

    &:before,
    &:after {
      display: none;
    }
  }

  a {
    display: block;
    margin-bottom: 10px;

    @include media-breakpoint-up('md') {
      display: inline-block;
      margin-right: 40px;
      margin-bottom: 0;
    }

    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      color: $colour-primary-grey;
    }

    &:focus {
      @include focus;
    }
  }
}

.copyright {
  white-space: nowrap;
}

@include media-breakpoint-up('md') {
  .backToTop {
    margin-right: 20px;
  }
}
