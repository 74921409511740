@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import 'styles/settings/variables';
@import 'styles/tools/mixins';

.content {
  text-align: left;
  padding: 16px;

  > *:first-child {
    padding: 0;
  }

  h3 {
    margin-left: 8px;
  }

  hr {
    border-top: 0;
  }

  button {
    padding-right: 4px;
    padding-left: 0;
    margin-left: 16px;
    width: calc(100% - 16px);

    &:hover {
      background-color: $colour-white !important;
    }
  }

  li {
    background-color: $colour-white !important;
  }
}
