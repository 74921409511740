@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../../styles/settings/variables';
@import '../../../../../styles/tools/mixins';

.wrapper {
  margin-bottom: 10px;

  @include media-breakpoint-up('md') {
    margin-bottom: 20px;
  }
    
  .text {
    color: $colour-waitrose-grey;
  }

  .shareOptions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .icon {
      color: $colour-waitrose-grey;
    }
  }
}