@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.siteLinks {
  background: $colour-scallop-grey;
  display: flex;
  flex: {
    basis: auto;
    direction: column;
    grow: 0;
    shrink: 0;
    wrap: nowrap;
  }
  justify-content: flex-start;
  padding: $grid-vertical-gutter;

  @include media-breakpoint-up('lg') {
    flex: {
      direction: row;
      wrap: wrap;
    }
    justify-content: center;
    padding: 0 $grid-vertical-gutter;
  }
}

.link {
  color: $text-color;
  font: {
    family: $font-family-base;
    size: $font-size-small; // ~14px
    weight: 200;
  }
  line-height: $grid-vertical-gutter * 2;
  padding: 0;
  text-align: left;

  @include media-breakpoint-up('lg') {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    line-height: 1em;
    margin: $grid-vertical-gutter;
    text-align: center;
  }
}
