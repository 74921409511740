@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

$warrant-caption-margin: 8px;
$warrant-right-margin: 16px;

.warrant {
  display: none;
  flex-direction: column;
  margin-right: $warrant-right-margin;

  @include media-breakpoint-up('lg') {
    display: inline-flex;
  }
}

.arms {
  align-self: center;
  height: 85px;
}

.caption {
  font: {
    family: $font-family-base;
    size: 5px;
  }
  line-height: 8px;
  margin-top: $warrant-caption-margin;
  text-align: center;
  text-transform: uppercase;
}
