@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../ingredients/styles/focus';
@import '../../../../styles/settings/variables';
@import '../../../wdx/common';
@import '../../variables';
@import '../mixins';


.btnMenu {
  @include focus-inset;
  @include site-header-link;

  align-items: center;
  border: {
    bottom: 0;
    left: 1px solid transparent;
    right: 1px solid transparent;
    top: 1px solid transparent;
  }
  background: transparent;
  color: $colour-primary-grey;
  display: flex;
  line-height: $links-height;
  padding: 0 20px 0 15px;
  position: relative;
  z-index: 1;

  @include media-breakpoint-up('xl') {
    margin-top: -1px;
  }

  svg {
    margin-left: 9px;
    margin-bottom: -1px;
  }


  &:focus {
    text-decoration: none;
    outline-offset: -1px;
  }

  &:hover {
    text-decoration: none;
  }

  &.scrolled {
    height: 47px;
    margin-top: 4px;
    padding-bottom: 4px;
  }
}

.menuActive {
  background-color: $colour-white;
  border: {
    bottom: 1px solid $colour-white;
    left-color: $colour-oyster-grey;
    right-color: $colour-oyster-grey;
    top-color: $colour-oyster-grey;
  }
  margin-bottom: -1px;

  svg {
    transform: scaleY(-1);
  }

  &::after {
    visibility: hidden;
  }
}
