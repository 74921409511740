@import '~@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins";
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables";

.pod {
  display: flex;
  margin-bottom: 8px;
  padding: 20px;
  border: 1px solid $colour-oyster-grey;
  min-height: 150px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .productInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    @media (max-width: 767px) {
      flex-direction: column;

      .productSwap {
        text-align: right;
      }
    }

    .productMain {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      @media (min-width: 768px) {
        align-items: center;
      }

      .image > img {
        width: 48px;
        height: 48px;
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .badges{
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          margin-bottom: 8px;

          .sponsoredBadge {
            margin-right: 8px;
          }
        }

        .quantity {
          color: $colour-waitrose-grey;
          margin-bottom: 2px;
        }

        .description {
          margin-bottom: 2px;
        }

        .textLink {
          display: inline;
        }

        .offers > div {
          margin-top: 0;
        }
      }
    }

    .productSwap {
      text-decoration: underline;
    }
  }

  .productControls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-top: 1px solid $colour-oyster-grey;
    padding-top: 20px;
    margin-top: 20px;
    width: 100%;

    @include media-breakpoint-up('md') {
      justify-content: space-between;
      border-top: 0;
      border-left: 1px solid $colour-oyster-grey;
      min-height: 150px;
      padding: 16px 20px;
      margin: 0;
      width: auto;
    }
  }
}

@media (min-width: 768px) {
  .pod {
    gap: 0;
    padding: 0;
    border: 1px solid $colour-oyster-grey;
  }

  .productInfo {
    display: flex;
    align-items: center;
    min-height: 150px;
    padding: 16px 20px;
  }
}

.highlightedIngredient {
  outline: 2px solid $colour-success-green;
  transition: outline 0.3s ease;
}