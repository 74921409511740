@import '../../common';

.link {
  margin-right: $header-link-margin;
}

.iconWrapper {
  position: relative;

  &.CANotPresent {
    &::after {
      position: absolute;
      right: 7px;
      top: -9px;
      background: $colour-waitrose-error-red;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      border: solid 2px $colour-white;
      content: '';
    }
  }

  .signInIcon {
    margin-top: -.25em;
    vertical-align: middle;
    margin-right: 12px;
  }
}



