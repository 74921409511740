@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';

//
// Forms
// --------------------------------------------------


// Normalize non-controls
//
// Restyle and baseline non-control form elements.

fieldset {
  padding: 0;
  margin: 0;
  border: 0;

  // Chrome and Firefox set a `min-width: min-content;` on fieldsets,
  // so we reset that to ensure it behaves more like a standard block element.
  // See https://github.com/twbs/bootstrap/issues/12359.
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: $line-height-computed;
  font-size: ($font-size-base * 1.5);
  line-height: inherit;
  color: $text-color;
  border: 0;
  border-bottom: 1px solid $colour-mushroom-grey;
}

label {
  display: inline-block;
  max-width: 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
  margin-bottom: 5px;
  font-weight: bold;
}


// Normalize form controls
//
// While most of our form styles require extra classes, some basic normalization
// is required to ensure optimum display with or without those classes to better
// address browser inconsistencies.

input {
  &[type='search'] {
    // Override content-box in Normalize (* isn't specific enough)
    box-sizing: border-box;

    // Search inputs in iOS
    //
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // `.form-control` class can properly style them. Note that this cannot simply
    // be added to `.form-control` as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    appearance: none;
  }

  // Position radios and checkboxes better
  &[type='radio'],
  &[type='checkbox'] {
    margin: 4px 0 0;
    margin-top: 1px \9; // IE8-9
    line-height: normal;

    // Apply same disabled cursor tweak as for inputs
    // Some special care is needed because <label>s don't inherit their parent's `cursor`.
    //
    // Note: Neither radios nor checkboxes can be readonly.
    &[disabled],
    &.disabled,
    fieldset[disabled] & {
      cursor: not-allowed;
    }
  }

  &[type='file'] {
    display: block;
  }

  // Make range inputs behave like textual form controls
  &[type='range'] {
    display: block;
    width: 100%;
  }

  // Focus for file, radio, and checkbox
  &[type='file'],
  &[type='radio'],
  &[type='checkbox'] {
    &:focus {
      @include tab-focus;
    }
  }

  &[type='number'],
  &[type='text'] {
    &[disabled] {
      @extend %disabled-control;
    }
  }
}

select {
  // Make multiple select elements height not fixed
  &[multiple],
  &[size] {
    height: auto;
  }

  &[disabled] {
    @extend %disabled-control;
  }
}
