@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';

//
// Tables
// --------------------------------------------------


table {
  background-color: $colour-transparent;

  // Table cell sizing
  //
  // Reset default table behavior

  col {
    &[class*='col-'] {
      position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-column;
    }
  }

  td,
  th {
    &[class*='col-'] {
      position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-cell;
    }
  }
}

caption {
  padding-top: $table-cell-padding;
  padding-bottom: $table-cell-padding;
  color: $text-muted;
  text-align: left;
}

th {
  text-align: left;
}


// Baseline styles

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $line-height-computed;

  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-cell-padding;
        line-height: $line-height-base;
        vertical-align: top;
        border: 0;
      }
    }
  }

  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 0;
  }

  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }

  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $colour-oyster-grey;
  }

  // Nesting
  .table {
    background-color: $body-bg;
  }
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  > tbody > tr {
    &:nth-of-type(odd) {
      background-color: $colour-scallop-grey;
    }
  }
}
