@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '@johnlewispartnership/wtr-ingredients/dist/foundations/colours';

.offer,
.offerMyWaitrose {
  &.withBackground {
    background-color: $colour-error-tint;
    width: calc(100% + 40px);
    left: -20px;

    &.trolleyPage{
      left:0;
      width: max-content;
      max-width: 240px;
    }

    &.green {
      background-color: $colour-corporate-lime-green;
    }

    @include media-breakpoint-down('xs') {
      width: calc(100% + 16px);
      left: 0;
    }

    a {
      width: 100%;
      outline:none;
    }
  }
}

.offer {
  margin: 10px auto;
  position: relative;

  & a {
    &:hover {
      text-decoration: none;
    }
  }
}

.offerMyWaitrose {
  display: flex;
  margin: 2px 0 auto;
  position: relative;

  & a {
    &:hover {
      text-decoration: none;
    }
  }
}

.myWaitrose {
  p {
    &::before {
      background: {
        color: $colour-white;
        image: url('../../MyWaitrose/mywaitrose-icon.png');
        size: contain;
      }
      color: $colour-offer-red;
      content: '';
      display: inline-block;
      height: 20px;
      margin-right: 10px;
      text-decoration: none;
      vertical-align: bottom;
      width: 27px;
    }
  }
}

.poundSignIcon {
  p {
    &::before {
      color: $colour-offer-red;
      content: '\E902';
      font: {
        family: 'WaitroseGlyph';
        size: 2.5em;
        style: normal;
        weight: normal;
      }
      line-height: 0;
      margin-left: -8px;
      text-decoration: none;
      vertical-align: middle;
    }
  }
}
