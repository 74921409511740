@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/tools/mixins';
@import '../../styles/settings/variables';

.body {
  background-color: $colour-scallop-grey;
  padding-bottom: 34px;

  &:empty {
    height: 100vh;
  }

  &.hideShopWindows :global(#cms-body-content) [data-test="shop-windows"] {
    @include media-breakpoint-up('lg')  {
      display: none;
    }
  }

}

.contentBackgroundColour {
  background-color: $colour-scallop-grey;
}
