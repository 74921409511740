@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '../../variables';

// Workaround due to Safari iOS 10 bug:
// 'align-items' and 'justify-content' are IGNORED when flex container is a <button>
// so in JSX, there is now an additional flex container wrapper inside <button>
// https://github.com/philipwalton/flexbugs/issues/236
.buttonWrapper {
  @include media-breakpoint-up('md') {
    align-items: center;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-left: -4px;
    width: 100%;
  }
}

.trolleySizeWrapper {
  color: $colour-primary-grey;
  position: absolute;
  right: -9px;
  top: -6px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up('md') {
    height: $header-button-height;
    position: static;
    width: 30px;
  }
}

.totalText {
  display: block;
  font-size: 11px;

  @include media-breakpoint-up('md') {
    display: none;
  }
}

.pricing {
  color: $text-color;
  display: block;
  font: {
    weight: 500;
    size: 13px;
  }
  line-height: initial;

  @include media-breakpoint-up('md') {
    font-size: 16px;
    line-height: 38px;
  }
}

.spinnerOverlay {
  align-items: center;
  background: $colour-white--alpha-90;
  color: $colour-waitrose-grey;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 998;

  [class*='sm'] {
    top: 0;
  }
}

@include media-breakpoint-down('sm') {
  .pricing {
    font-size: 16px;
    font-weight: 500;
  }

  .trolleySizeWrapper,
  .totalText {
    display: none;
  }

  .spinnerOverlay {
    overflow: initial;
  }
}
