@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';

.updateBtn {
    width: 88px;

    &.hide {
      display: none;
    }
  }


.updated {
    border: 1px solid $colour-success-green;
    background: $colour-success-green-tint;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.quantityButtons {
    display: flex;
    gap: 8px;

    &.hide {
        display: none;
    }
}

