$top-height: 40px;
$logo-height-xs: 45px;
$logo-height-sm: 70px;
$main-padding-bottom: 12px;
$input-height-xs: 30px;
$border-radius-default: 5px;
$links-border-width: 1px;
$links-height: 41px;
$header-button-height: 40px;
$header-button-width-md: 104px;
$header-button-width-xl: 132px;
$header-gutter-width: 32px;
$slide-duration: 200ms;
$specialist-shops-links-size: 30px;
$header-unitless-height: 50;
$header-height: #{$header-unitless-height}px;
$transition-time: .2s;