@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';

$message-height: $grid-vertical-gutter * 2.5;

.message {
  background: $colour-mushroom-grey;
  display: none;
  height: $message-height;
  line-height: $message-height;
  margin: 0;
  overflow: hidden;
  text-align: center;
  transition: height .6s linear;
  white-space: nowrap;
  width: 100%;

  &.visible {
    display: block;
  }
}
