@import '@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins";
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables";


.modalHeader {
  text-align: center !important;
  top: 0;
}

.recipesAccordion {
  background-color: $colour-eggshell-grey;
  padding: 16px 16px 128px;

  > button {
    background: $colour-white !important;
    padding: 16px 16px 0;
    border: 1px solid $colour-oyster-grey !important;
    margin-bottom: 16px;

    > div:first-child {
      border-right: 0 !important;
    }

    &:active,
    &:focus {
      outline: 0;
      background: $colour-white !important;
    }

    &[aria-expanded='true'] {
      border-bottom: 0 !important;
      margin-bottom: 0;
    }

    +div {
      background: $colour-white;
      border: 1px solid $colour-oyster-grey;
      border-top: 0;
      margin: 0 0 12px;
    }
  }

  hr {
    display: none;
  }
}

.recipesAccordionItem {
  margin: 16px;

  ul {
    margin-bottom: 0 !important;
  }
}

.content {
  background: $colour-eggshell-grey;
  height: 100%;
}

.accordionTitleWrapper {
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down('md') {
    display: block;
  }
}

.recipeTitleWrapper {
  flex: 1;
  max-width: 60%;
  display: flex;
  @include media-breakpoint-down('md') {
    max-width: 100%;
  }
}

.recipeTitle {
  display: flex;
  margin-bottom: 16px;
  margin-left: 16px;
}

.recipeInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.recipeIngredientsNumber,
.recipePrice {
  white-space: nowrap;
  font-weight: 500;
  @include media-breakpoint-down('md') {
    display: block;
  }
}

.recipeIngredientsNumber {
  @include media-breakpoint-down('md') {
    float: left;
    margin-left: 36px;
  }
}

.recipePrice {
  @include media-breakpoint-down('md') {
    float: right;
    margin-right: -44px;
  }
}

.recipeCheckbox {
  &:focus {
    +div {
      outline: 0 !important;
    }
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // Specific requirement for Meal Planner
  @media (max-width: 800px) {
    display: block;
    height: 110px;

    > button {
      width: 100% !important;
    }
  }
}

.total {
  flex: 1;
}

.totalLabel {
  margin-right: 8px;
  font-weight: 500;
}

.totalPrice {
  font-weight: 500;

  // Specific requirement for Meal Planner
  @media (max-width: 800px) {
    float: right;
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include media-breakpoint-down('md') {
    height: 100%;
  }

  @include media-breakpoint-up('md') {
    justify-content: flex-start;
    margin-top: 100px;
  }
}

.successWrapper {
  @extend .loadingWrapper;

  .successIcon {
    color: $colour-success-green;
  }

  .warningIcon {
    color: $colour-error-red;
  }
}

.trolleyConflict {
  margin-top: 12px;
}
