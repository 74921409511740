@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.accordion {
  border-bottom: 1px solid $colour-mushroom-grey;
  margin: {
    bottom: 5px;
    left: $grid-vertical-gutter;
    right: $grid-vertical-gutter;
  }

  h2 {
    background: $colour-white;
    border-bottom: 0;
    color: $text-color;
    font: {
      family: $font-family-base;
      size: $font-size-large; // ~18px
      weight: normal;
    }
    padding: 0;

    &:hover {
      background: $colour-white;
      text-decoration: underline;
    }

    &::after {
      content: '\e929';
    }
  }
}

.linkSection {
  @include media-breakpoint-up('lg') {
    border-left: 1px solid $colour-mushroom-grey;
    flex: {
      basis: 25%;
      grow: 1;
      shrink: 0;
    }

    &:first-child {
      border-left: 0;
    }
  }
}

.linkSectionTitle {
  display: none;

  @include media-breakpoint-up('lg') {
    color: $text-color;
    display: block;
    font: {
      family: $font-family-base;
      size: $font-size-large; // ~18px
      weight: normal;
    }
    padding: 0 $grid-vertical-gutter;
    margin-top: 0;

    .siteLinks & {
      display: none;
    }
  }
}

.footerLink {
  color: $text-color;
  display: block;
  font: {
    family: $font-family-base;
    size: $font-size-base; // ~16px
    weight: 200;
  }
  line-height: 1em;
  margin: ($grid-vertical-gutter * .5) 0;
  padding: ($grid-vertical-gutter * .375) 0;
  text-align: left;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    outline: 0;
    text-decoration: none;

    > span {
      text-decoration: underline;
    }
  }

  // [BM] SSoT
  .accordion div[class^='body___'] div:last-child & {
    padding-bottom: $grid-vertical-gutter;
  }

  @include media-breakpoint-up('lg') {
    &:last-child {
      margin-bottom: 0;

      // Note for IE 10/11 padding cannot be applied to flex container, so apply to these children instead
      // See: https://github.com/philipwalton/flexbugs#7-flex-basis-doesnt-account-for-box-sizingborder-box
      padding: {
        left: $grid-vertical-gutter;
        right: $grid-vertical-gutter;
        bottom: 0;
      }
    }
  }
}

.openlink {
  margin-left: 8px;
}
