@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import './variables';

%side-item {
  flex: 0 1 ($ingredients-unit * 20);

  @include media-breakpoint-up('md') {
    flex-basis: auto;
  }
}

%hidden-on-small {
  display: none;

  @include media-breakpoint-up('md') {
    display: block;
  }
}

.header {
  background-color: $colour-white;
  position: relative;
  width: 100%;
  z-index: $zindex-navbar-fixed;
}

.desktop {
  display: block;
  position: relative;
  width: 100%;
  z-index: $zindex-header;
}

.desktopHeader {
  border-bottom: 1px solid $colour-oyster-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $colour-white;
  transition: top $transition-time ease-in-out;
  top: -#{$header-height};
  height: $header-height;
  width: 100%;

  @include media-breakpoint-up('lg') {
    height: auto;
  }
}

.container {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;

  @include media-breakpoint-up('md') {
    flex-wrap: wrap;
  }
}

.mobileSearch {
  display: flex;
  align-items: center;
  padding-top: 8px;

  label {
    margin: 0;
    line-height: 0;
  }

  svg {
    cursor: pointer;
  }
}

.searchBtn {
  background: transparent;
  border: 0;
  box-shadow: none;
  display: flex;
  outline-color: $colour-focus-blue;
  padding: 0;

  i {
    display: flex;
  }

  &:hover { 
    box-shadow: none;
  }
}

.linksHP4 {
  background-color: $colour-white;
  position: relative;
  z-index: 1;
}

.multiSearch {
  transition: top $transition-time ease-in-out;
  position: relative;
  width: 100%;
  top: 0;
}

.headerPhase4 {
  transition: top $transition-time ease-in-out;
}

.multiSearchScrolled {
  top: 40px;
  position: fixed;

  @include media-breakpoint-up('md') {
    top: 50px;
  }
}

.overlay {
  display: none;

  @include media-breakpoint-up('lg') {
    display: inherit;
  }
}

.inner {
  display: flex;
  flex: 2 0 50%;
  height: 40px;
  margin: 13px 0;
  position: relative;
  z-index: 5;
  justify-content: flex-end;
  
  @include media-breakpoint-up('md') {
    flex-wrap: nowrap;
    flex: 2 0 75%;
  }
}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  height: 51px;
}

.static {
  position: absolute;
  top: 0;
}

.logo {
  display: flex;
  flex: 1 0 25%;

  .scrolled & {
    display: none;
  }
}

.mobileLogo {
  flex: 1 0 15%;
  margin: 10px 2px 5px;
}

.menu {
  display: flex;
  flex: 0 0 auto;
  margin-left: -16px;

  .scrolled & {
    flex: 1 0 25%;
  }
}

.mobileMenu {
  display: flex;
  min-width: 40px;
}

.links {
  display: block;
  flex: 2 0 80%;

  @include media-breakpoint-up('xl') {
    flex: 2 0 85%;
  }

  .scrolled & {
    display: none;
  }
}

.search {
  display: flex;
  flex: 1 1 auto;
  width: auto;
}

.trolley {
  display: flex;
  flex-basis: auto;
  justify-content: flex-end;
  margin-left: $ingredients-unit * 2;
  position: relative;
}

.signInOrTrolley {
  @extend %side-item;
  text-decoration: underline;
  display: flex;
  align-items: center;
  flex-basis: 0;
  height: $top-height;
  margin-left: 20px;
  max-width: ($ingredients-unit * 20);
  position: relative;
  white-space: nowrap;
}

.slot {
  display: block;
  margin-left: ($ingredients-unit * 4) + 1px; // 1px to cater for box-shadow outline of adjacent search box
}

.mobileSlot {
  align-items: center;
  display: none;
  white-space: nowrap;
  margin-left: 20px;


  @media (min-width: 375px) {
    align-items: center;
    display: flex;
    }
}
