@import '../../../SiteHeader/variables';
@import '../../../../ingredients/styles/focus';
@import '../../../../ingredients/styles/spacing';
@import '../../../wdx/common';
@import '@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import '../../shared';
@import '../../LinksBar/_mixins.scss';

$title-height: 45px;

.btnMenu {
  @include focus-inset;
  @include site-header-link;

  align-items: center;
  border: {
    bottom: 0;
    left: 1px solid transparent;
    right: 1px solid transparent;
    top: 1px solid transparent;
  }
  background: transparent;
  color: $colour-primary-grey;
  display: flex;
  line-height: $links-height;
  padding: 0 20px;
  position: relative;
  z-index: 1;

  svg {
    margin-left: 9px;
    margin-bottom: -1px;
  }


  &:focus {
    text-decoration: none;
    outline-offset: -1px;
  }

  &:hover {
    text-decoration: none;
  }
}

.menuActive {
  background-color: $colour-white;
  border: {
    bottom: 1px solid $colour-white;
    left-color: $colour-oyster-grey;
    right-color: $colour-oyster-grey;
    top-color: $colour-oyster-grey;
  }
  margin-bottom: -1px;

  svg {
    transform: scaleY(-1);
  }

  &::after {
    visibility: hidden;
  }
}

.overlay {
  top: 100px;
}

.close {
  @include focus;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: none;
  line-height: 0;
  margin: $ingredients-unit * 4 $ingredients-unit * 3.5 $ingredients-unit * 2.5  0;
  outline-color: $colour-focus-blue;
  overflow: hidden;
  position: absolute;
  padding: 0;
  right: 0;
  top: 0;
  width: fit-content;

  @include media-breakpoint-up('lg') {
    display: block;
  }

  i {
    padding: 0;

    svg {
      height: 28px;
      width: 28px;
    }
  }
}

.closeLink {
  @include focus-inset;
  cursor: pointer;
  display: inline-flex;
  background: transparent;
  font-size: 24px;
  height: 100%;
  overflow: hidden;
  position: absolute;
  padding: 0;
  right: 0;
  top: 0;
  width: $title-height;

  i {
    padding: 0;

    svg {
      height: 28px;
      width: 28px;
    }
  }
}

.loading {
  padding: 20px;
}

.menuCloseWrapper {
  position: absolute;
}

.menus {
  display: flex;
  flex-direction: row;
  overflow: {
    x: hidden;
    y: auto;
  }
  -webkit-overflow-scrolling: touch; // smooth scroll on iOS
  width: 24%;

  &:focus {
    outline: thin dotted;
  }
}

.nav {
  background-color: $colour-white;
  left: initial;
  max-width: 0;
  opacity: 0;
  position: absolute;
  right: initial;
  transition: opacity $slide-duration;
  user-select: none;
  z-index: -1;
  overflow: hidden;
  display: none;

  @include media-breakpoint-up('lg') {
    max-height: 602px;
    max-height: calc(100vh - 180px);
    top: 107px;
  }

  &.open {
    flex-direction: column;
    max-width:  836px;
    opacity: 1;
    transition: opacity $slide-duration;
    width: 100%;
    z-index: 0;
    display: block;

    @include media-breakpoint-up('lg') {
      border: 1px solid $colour-oyster-grey;
      width: calc(100% - 32px);
    }

    @include media-breakpoint-up('xl') {
      max-width:  930px;
    }
  }
}

.headingBar {
  display: none;
  flex-direction: column-reverse;
  padding: 8px 0 6px 8px;
  justify-content: space-between;

  @include media-breakpoint-up('lg') {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  :first-child {
    border-top: solid 1px $colour-oyster-grey;;
    padding: 16px 16px 0;
    margin-top: 8px;

    @include media-breakpoint-up('lg') {
      border: 0;
      padding-top: 0;
    }
  }
}

.subHeading {
  padding: 10px 20px;

  @include media-breakpoint-up('lg') {
    padding: 0 0 10px;
  }
}

.topPanel {
  background-color: $colour-eggshell-grey;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up('lg') {
    max-height: 232px;
    width: 100%;
    flex-direction: row;
    padding: 0 16px;
  }
}

.bottomPanel {
  display: flex;
  overflow: hidden;
  margin: 12px 0 0;
  padding: 4px 12px 0;
  flex-direction: column;
  justify-content: space-evenly;

  @include media-breakpoint-up('lg') {
    flex-direction: row;
    padding: 4px 0 0;
  }
}

.panel {
  display: flex;
  width: 100%;
  flex-direction: column;

  @include media-breakpoint-up('lg') {
    padding: 12px 0 0 8px;
    width: 50%;
    border: 0;
  }
}

.divider {
  @include media-breakpoint-up('lg') {
    border-right: solid 1px $colour-oyster-grey;
    margin: 12px 0;
  }
}

.panelContent {
  display: flex;
  -ms-overflow-style: none;
  flex-direction: column;

  @include media-breakpoint-up('lg') {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.links {
  border-bottom: $colour-oyster-grey 1px solid;
  background: $colour-white;
  padding: 8px 18px;
  line-height: 24px;
  font-weight: 300;
  width: calc(100% - 8px);

  &:focus {
   @include focus-outline
  }

  @include media-breakpoint-up('lg') {
    margin-bottom: 8px;
    padding: 0;
    background: none;
    border-bottom: none;
  }
}

.titleLinks {
  letter-spacing: 2px;
  padding: 15px 18px 8px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.429;
  font-weight: 400;

  @include media-breakpoint-up('lg') {
    background: none;
    border-bottom: none;
    letter-spacing: 4px;
    padding: 0;
  }
}

.topLink {
  margin-right: 60px;
  margin-left: 16px;

  &:focus {
    @include focus-outline
  }

  @include media-breakpoint-up('lg') {
    text-decoration: underline;
  }
}

.menuShopAll {
  display: flex;

  a {
    font-weight: 400;
    width: 100%;
  }

  @include media-breakpoint-up('lg') {
    display: none;
  }
}

.linkPanel {
  display: flex;
  flex-direction: column;
  width: 245px;

  &:focus {
    @include focus-outline;
    outline-offset: 0;
  }

  @include media-breakpoint-up('xl') {
    width: 275px;
  }

  p {
    letter-spacing: .2em;
  }
}

.linkImg {
  width: 100%;
  margin-bottom: 12px;
}

.panelWrapper {
  overflow: hidden;
  overflow-y: auto;
}

.panelWrapper :global(#cms-seasonalMenu-content) {
  section {
    padding: 0;
  }

  section[class*="richText"] {
    margin-bottom: 0;
  }

  :global(.vertical-text-links) {
    margin-left: 0;
  }

  :global(.vertical-text-links) a:first-child {
    font-weight: 400;
  }

  :global(#seasonal-links-secondary) {
    background-color: $colour-scallop-grey;
  }

  :global(#seasonal-links-secondary .vertical-text-links) {
    margin-top: 32px;
  }

  :global(#seasonal-cards) {
    padding: 16px 12px;

    > [class*="grid"] {
      gap: 12px;
    }

    [class*="grid"] {
      text-decoration: inherit;
    }

    a {
      padding: 0;
      text-decoration: none;

      @media(hover: hover) {
        &:hover{
          text-decoration: underline;
        }
      }
    }

    a p {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 2.8px;
      line-height: 20px;
      text-decoration: inherit;
    }

    a section {
      text-decoration: inherit;
      margin-bottom: 12px;
    }
  }

  @include media-breakpoint-up('lg') {
    :global(.vertical-text-links) a {
        padding-left: 0;
    }

    :global(#seasonal-links) {
      padding: 12px 24px;
      background-color: $colour-eggshell-grey;
    }

    :global(#seasonal-links-secondary) {
      padding-left: 24px;
      border-left: 1px solid $colour-oyster-grey;
      background-color: initial;
    }

    :global(#seasonal-links-secondary .vertical-text-links) {
      margin-top: 0;
    }

    :global(#seasonal-cards) {
      padding: 16px 24px 8px;

      > [class*="grid"] {
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: nowrap;
      }

      a {
        width: 245px;
      }
    }
  }

  @include media-breakpoint-up('xl') {
    :global(#seasonal-cards) {
      a {
        width: 275px;
      }
    }
  }
}
