@import '~@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import '../../styles/tools/mixins';
@import '../../styles/settings/variables';


.container {
  border-bottom: 1px solid $colour-oyster-grey;
  max-width: map-get($grid-breakpoints, 'xl');

  &.backgroundWhite{
    background-color: $colour-white;
    max-width: 100%;

    @include media-breakpoint-up('xl') {
      padding: 0 calc((100% - map-get($grid-breakpoints, 'xl')) / 2);
    }
  }

  &.white {
    border-bottom: 1px solid $colour-white;
  }

  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-down('md') {
    padding: 0;
  }
}

.hide {
  display: none;

  @include media-breakpoint-up('lg') {
    display: block;
  }
}

.end {
  margin: 0 10px;
  min-width: fit-content;
}
