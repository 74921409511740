// Grid mixins
@use "sass:math";

@mixin build-grid-breakpoint($breakpoint) {

  .col-#{$breakpoint}-hide {
    @include media-breakpoint-only($breakpoint) {
      display: none;
    }
  }

  @for $cols from 1 through map-get($grid-config, columns) {
    @for $col from 0 through ($cols - 1) {
      $col-percentage: percentage(math.div($col, $cols));
      $col-set-auto: if($col == 0, auto, $col-percentage);

      .col-#{$breakpoint}-push-#{$col}of#{$cols} {
        left: $col-set-auto;
      }

      .col-#{$breakpoint}-pull-#{$col}of#{$cols} {
        right: $col-set-auto;
      }

      .col-#{$breakpoint}-offset-#{$col}of#{$cols} {
        margin-left: if($col == 0, 0, $col-percentage);
      }

      .col-#{$breakpoint}-#{($col + 1)}of#{$cols} {
        float: left;
        width: percentage(math.div($col + 1, $cols));
      }

      .container--flex .col-#{$breakpoint}-#{($col + 1)}of#{$cols} {
        flex: 0 0 auto;
        float: none;
      }
    }
  }
}

@mixin build-grid() {
  $grid-margin: map-get($grid-config, grid-margin);
  $grid-margin-lg: map-get($grid-config, grid-margin-lg);
  $gutter: map-get($grid-config, gutter) * 0.5;

  .container-fluid {
    @include clearfix;
    padding-right: $grid-margin;
    padding-left: $grid-margin;
    margin-right: auto;
    margin-left: auto;
    max-width: map-get($grid-breakpoints, map-get($grid-config, max-width)) !important;
    width: 100%;

    @include media-breakpoint-up('lg') {
      padding-right: $grid-margin-lg;
      padding-left: $grid-margin-lg;
    }

    &--flex {
      &::after {
        display: none;
      }
    }
  }

  .row {
    @include clearfix;
    margin-right: $gutter * -1;
    margin-left: $gutter * -1;

    .container--flex & {
      display: flex;
      flex-wrap: wrap;

      &::after {
        display: none;
      }
    }
  }

  [class^='col-'] {
    flex: 0 0 100%;
    float: none;
    width: auto;
  }

  %col {
    min-height: 1px;
    padding-right: $gutter;
    padding-left: $gutter;
    position: relative;
  }

  @each $breakpoint, $min-width in $grid-breakpoints {
    .container--flex {
      .col-#{$breakpoint}-first {
        order: -1;
      }

      .col-#{$breakpoint}-last {
        order: 1;
      }

      .col-#{$breakpoint}-top {
        align-self: flex-start;
      }

      .col-#{$breakpoint}-center {
        align-self: center;
      }

      .col-#{$breakpoint}-bottom {
        align-self: flex-end;
      }

      .row-#{$breakpoint}-top {
        align-items: flex-start;
      }

      .row-#{$breakpoint}-center {
        align-items: center;
      }

      .row-#{$breakpoint}-bottom {
        align-items: flex-end;
      }
    }

    @for $cols from 1 through map-get($grid-config, columns) {
      @for $counter from 1 through $cols {
        .col-#{$breakpoint}-#{$counter}of#{$cols} {
          @extend %col;
        }
      }
    }

    @if $min-width == 0 {
      @include build-grid-breakpoint($breakpoint);
    } @else {
      @include media-breakpoint-up($breakpoint) {
        @include build-grid-breakpoint($breakpoint);
      }
    }
  }
}

@include build-grid;
