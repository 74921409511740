@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '../wdx/mixins/typography';
@import './variables';

.modal {
  @extend %modal;
}

.modalBodyNoScroll {
  overflow: hidden;
}

.modalCloseButton {
  @extend %modal-close-button;
}

.modalOverlay {
  @extend %modal-overlay;
}

.modalOverlayOpen {
  @extend %modal-overlay-open;
}

.modalInnerContent {
  padding: ($ingredients-gutter * 2) $ingredients-gutter ($ingredients-gutter);
  text-align: center;

  @include media-breakpoint-up('md') {
    padding: ($ingredients-gutter * 2) ($ingredients-gutter * 2) ($ingredients-gutter * 2);
  }

  h3 {
    @include title-modal;
    margin: {
      bottom: $font-size-base * .625;
      top: 0;
    }
  }
}

.modalText {
  @include paragraph;
  margin: $ingredients-gutter 0 0;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: $ingredients-gutter 0 0;
  padding: 0 ($ingredients-gutter * .5);
  width: 100%;

  &.flex {
    padding: 0;

    @include media-breakpoint-up('md') {
      display: flex;

      li {
        display: flex;
      }
    }
  }

  &.vertical {
    li {
      display: block;
      margin: {
        bottom: $ingredients-gutter*.5;
        left: auto;
        right: auto;
      }
      flex-grow: 1;


      &:last-child {
        margin: {
          bottom: 0;
          right: auto;
        }
      }

      @include media-breakpoint-up('md') {
        width: 80%;
      }
    }
  }

  li {
    display: inline-block;
    flex-grow: 1;
    justify-content: center;
    margin-bottom: $ingredients-gutter*.5;

    @include media-breakpoint-up('sm') {
      margin-bottom: 0;
    }

  }
}

.asideText {
  @include paragraph-sub('medium');
  color: $text-color;
  display: block;
  font-style: italic;
  margin-top: $ingredients-unit * 4;

  @include media-breakpoint-up('sm') {
    margin: ($ingredients-unit * 4) auto;
    width: 80%;
  }
}
