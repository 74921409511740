@mixin trolley-control-conflict {
  [class^='conflictWrapper'] & {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    font-weight: 400;
    font-size: 16px;
    background: none;
    text-align: left;
    line-height: 1.5;

    @include media-breakpoint-up('sm') {
      text-align: center;
    }
  }
}
