.container {
  margin-top: 40px;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    & > span {
      line-height: 1.25;
      display: block;
    }
  }
}

.group {
  margin-top: 36px;

  &:first-child {
    margin-top: 0;
  }
}

.no-space {
  span {
    &:first-child {
      &::after {
        font-size: 4px;
      }
    }
  }
}
