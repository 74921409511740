@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../variables';

.shoppingSummary {
  @include media-breakpoint-up('md') {
    margin-left: 8px;
  }

  h4 {
    font-size: 20px;
    font-family: $font-family-base;
  }

  p {
    font-family: $font-family-base;
    font-size: 16px;
    margin: 0 0 3px;
  }

  @include media-breakpoint-down('sm') {
    align-items: center;
    display: flex;

    .trolleyBtn {
      border: 0;
      margin-top: 0;
      padding: 0;
      z-index: 1;
      position: relative;
      display: block;
    }
  }
}

.trolleyActions {
  position: relative;

  @include media-breakpoint-up('md') {
    width: 144px;
  }
}

%trolley-button {
  background-color: $colour-white;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  @include media-breakpoint-down('sm') {
    font: {
      family: Waitrose;
      size: 14px;
    }
    padding: 2px 35px 0 6px;
    text-align: right;
    vertical-align: initial;

    &,
    &:hover,
    &:focus,
    &:active {
      background-color: $colour-white;
      border: 0;
      box-shadow: none;
      color: $text-color;
    }
  }

  @include media-breakpoint-up('md') {
    padding: 0;
    width: 144px;
    border: 1px solid $colour-primary-grey;
    height: 40px;

    &:hover,
    &:focus {
      border-width: 2px;
    }
  }
}

.trolleyLink {
  @extend %trolley-button;
  display: flex;
  overflow: hidden;
  padding: 0;

  &:focus {
    @extend %trolley-button;
    padding: 0;
    text-decoration: underline;
    outline: {
      color: $colour-focus-blue;
      offset: $focus-outline-width;
      style: solid;
      width: $focus-outline-width;
    }
  }
}
