@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../shared';

$icon-size: 28px;
$colour-black: #000; /* stylelint-disable-line @johnlewispartnership/eslint-plugin-wtr-ingredients/no-colour-literals */

.menu {
  background: $colour-scallop-grey;
  bottom: 0;
  display: block;
  left: 0;
  margin: 0;
  overflow-y: scroll;
  padding: 0;
  position: fixed;
  top: 0;
  width: 270px;
  z-index: 102;
  -webkit-overflow-scrolling: touch; // smooth scroll on iOS

  @include media-breakpoint-up('lg') {
    display: none;
  }

  i {
    padding: 0;
  }
}

.menuLevel {
  height: auto;
  padding-bottom: 60px;
  width: 100%;

  &.cmsLevel {
    background: $colour-white;
  }
}

.menuWrapper {
  align-items: flex-start;
  display: flex;
  margin-left: -5px;
  width: fit-content;
}

.closeWrapper {
  position: relative;
}

.topWrapper {
  background-color: $colour-white;
  border-bottom: 1px solid $colour-oyster-grey;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 11;
}

.menuButton {
  @include focus-inset;
  background: none;
  border: 0;
  line-height: 0;
  margin-left: -5px;
  outline-color: $colour-focus-blue;
  padding: 10px;
  position: relative;

  &.CANotPresent::after {
    position: absolute;
    right: 5px;
    top: 6px;
    background: $colour-waitrose-error-red;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: solid 2px $colour-white;
    content: '';
  }
}

.menuButtonWithLoading {
  @extend .menuButton;
  color: $colour-black;
  min-width: auto;
  min-height: auto;

  &:hover,
  &:focus {
    box-shadow: none;
    background-color: transparent;
    color: $colour-black;
  }

  svg {
    display: flex;
  }
}

.placeholderMenuIcon {
  position: absolute;
  top: 14.25px;
  left: 21px;
  z-index: 51;
}

.hide {
  visibility: hidden;
}

.iconButton {
  background: $colour-white;
  border: 0;
}

.backButton {
  @include focus-inset;
  display: flex;
  align-items: center;
  font-weight: 300;
  line-height: 1.5;
  outline-color: $colour-focus-blue;
  overflow: hidden;
  padding: ($ingredients-unit * 2.5) ($ingredients-unit * 4);
  text-overflow: ellipsis;
  white-space: nowrap;
}

%icons {
  height: $icon-size;
  width: $icon-size;

  svg {
    height: 100%;
    width: 100%;
  }
}

.icon {
  @extend %icons;
  margin-right: 10px;
}

.closeButton {
  @include focus;
  background: transparent;
  border: 0;
  margin: $ingredients-unit * 3.75 $ingredients-unit * 4.5 $ingredients-unit * 2.5 0;
  outline-color: $colour-focus-blue;
  width: fit-content;
}

.specialistShops {
  background-color: $colour-white;
  padding-bottom: 12px;

  &::before {
    background-color: $colour-scallop-grey;
    content: '';
    display: block;
    height: 55px;
  }
}

.middleMenu {
  padding-bottom: 12px;
}

.middleMenu :global(#mega-menu-second-column) {
  background-color: $colour-scallop-grey;
  padding: 0;
  margin-bottom: 16px;

  section {
    padding: 0;
    margin-bottom: 0;
  }

  h4 {
    @extend %nav-title;
    padding: 24px 20px 12px;
  }
}

.navTitle {
  @extend %nav-title;
}

.navSubTitle {
  font: {
    size: 14px;
    weight: 400;
  }

  display: block;
  letter-spacing: normal;
  margin: 0;
  overflow: hidden;
  padding: 0;
  white-space: normal;
}

.menuOverlay {
  background-color: $colour-primary-grey--alpha-70;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 101;
}

.smallWrapper {
  width: 100%;
  margin-bottom: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.heading {
  font-weight: 400;
  line-height: 1.5;
  white-space: pre;
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-top: 16px;
  padding-left: 16px;
  width: 100%;
}

.banner {
  margin-top: $ingredients-unit * 5;
  margin-left: $ingredients-unit * 5;
  margin-right: $ingredients-unit * 5;

  :global(#cms-banner-content) > * {
    width: 100%;
  }

  [id^='trading-asset-grid'] {
    padding: 0;
  }
}
