@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../../SiteHeader/variables';

$header-topnav-height: 40px;
$header-warrants-margin: 50px;

%header-navigation-row {
  align-items: center;
  display: flex;
  height: $header-topnav-height;
  justify-content: flex-end;
  padding: 0 14px;
}

.bottomLinks,
.topLinks {
  flex-grow: 1;
}

.bottomNav {
  @extend %header-navigation-row;
  border-top: 1px solid $colour-mushroom-grey;

  @include media-breakpoint-up('md') {
    display: none;
  }
}

.branding {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 26px;

  @include media-breakpoint-up('sm') {
    margin-top: 28px;
  }

  @include media-breakpoint-up('md') {
    margin-top: 30px;
  }

  @include media-breakpoint-up('lg') {
    margin-top: 38px;
  }
}

.header {
  position: relative;
  z-index: $zindex-navbar;
}

.logo {
  flex-grow: 0;
}

.search {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 23px $grid-vertical-gutter 11px;

  @include media-breakpoint-up('sm') {
    margin-top: 25px;
  }

  @include media-breakpoint-up('md') {
    margin: {
      bottom: 30px;
      top: 27px;
    }
  }

  @include media-breakpoint-up('lg') {
    margin: {
      bottom: 30px;
      top: 35px;
    }
  }
}

.searchWrapper {
  width: 100%;

  @include media-breakpoint-up('md') {
    width: 450px;
    margin-right: 20px;
  }

  form {
    max-width: 345px;
  
    @include media-breakpoint-down('sm') {
      max-width: 100%;
    }
    @include media-breakpoint-up('lg') {
      max-width: 475px;
    }

  }
}

.slotWrapper {
  margin-top: 10px;
  width: 100%;

  @include media-breakpoint-up('md') {
    margin-top: 0;
    width: auto;
  }
}

.topLinks {
  display: none;

  @include media-breakpoint-up('md') {
    display: flex;
  }
}

.topNav {
  @extend %header-navigation-row;
  border-bottom: 1px solid $colour-mushroom-grey;
  margin-bottom: 10px;
}

.warrants {
  align-items: center;
  display: flex;
  left: $header-warrants-margin;
  position: absolute;
}

.signIn {
  & > a {
    display: inline-block;
  }
}
