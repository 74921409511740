@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../styles/settings/variables';
@import '../wdx/mixins/typography';

$background-colour: $colour-waitrose-green;
$padding: $ingredients-unit * 3;
$text-colour: $colour-white;

.skipLink {
  @include title-alert('bold');

  background: $background-colour;
  color: $text-colour;
  font: {
    family: $font-family-base;
    stretch: normal;
    style: normal;
  }
  letter-spacing: 1px;
  left: 0;
  opacity: 0;
  padding: ($padding * .5) $padding;
  pointer-events: none;
  position: absolute;
  text-transform: uppercase;
  transform: translateY(-100%);
  transition: opacity .5s, transform .5s;
  z-index: $zindex-skip-links;

  &:focus {
    color: $text-colour; // overriding 'a' defaults
    opacity: 1;
    outline: 0;
    pointer-events: auto;
    text-decoration: none;
    transform: translateY(0);
  }
}
