// sass-lint:disable no-important

@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.content {
  textarea {
    outline: {
      color: $colour-primary-grey !important;
      offset: 0 !important;
      width: 2px !important;
    }

    &::placeholder {
      color: $colour-earl-grey !important;
      font-style: normal !important;
    }
  }
  @include media-breakpoint-up('sm') {
    textarea {
      min-height: 378px;
      max-height: 378px;
      padding: 20px !important;
    }
  }
}
