@mixin extendBackground($background-colour) {
    background-color: $background-colour;
    position: relative;
    margin-left: -10000px;
    padding-left: 10000px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -9999px;
        right: 0;
        box-shadow: 9999px 0 0 $background-colour
  }
}
