@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../../SiteHeader/variables';

$autocomplete-side-outset: 16px;

@mixin autocomplete-full-width($margin-top) {
  border-top: 1px solid $colour-mushroom-grey;
  left: 0;
  outline: 2px solid $colour-primary-grey;
  top: 100%;
  width: 100%;
}

@mixin autocomplete-dropdown() {
  border-top: 0;
  left: 0;
  margin-top: 0;
  padding: 0;
  top: 40px;
  width: 100%;
}

.autoComplete {
  background-color: $colour-white;
  padding: 0;
  position: absolute;

  &::before {
    background-color: $colour-white;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:not(.homepage) {
    @include autocomplete-full-width(10px);

    @include media-breakpoint-up('sm') {
      @include autocomplete-dropdown;
    }
  }

  &.homepage {
    @include autocomplete-full-width(20px);

    @include media-breakpoint-up('sm') {
      @include autocomplete-dropdown;
    }
  }

  &.inline {
    border: 0;
    outline: none;
    position: absolute;
    top: 63px;

    &::before {
      box-shadow: none;
      position: static;
    }

    .suggestion {
      padding: 0 28px;
    }
  }
}

.suggestions {
  position: relative;
  font: {
    family: $font-family-base;
    size: 16px;
    weight: 200;
  }
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.suggestion {
  cursor: pointer;
  height: $ingredients-unit * 8;
  line-height: $ingredients-unit * 8;
  padding: 0 $ingredients-unit * 4;
  text-align: left;

  &.selected {
    background-color: $colour-focus-blue;
    color: $colour-white;
  }

  &:focus,
  &:hover {
    background-color: $colour-mushroom-grey;
  }

  @include media-breakpoint-up('sm') {
    padding: 0 ($ingredients-gutter * .5);
  }

  &.disabled {
    opacity: .6;
    pointer-events: none;
  }
}
