.title {
  margin-bottom: 24px;
}

.alertWrapper {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
}

.applyButton {
  margin-top: 24px;
}
