@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../utils/utils.scss';

$md: map-get($grid-breakpoints, 'md');
$lg: map-get($grid-breakpoints, 'lg');

@mixin container-up ($breakpoint) {
  @container recipe (min-width: #{$breakpoint}) {
    @content;
  }
}

@mixin container-down ($breakpoint) {
  @container recipe (max-width: #{calc($breakpoint - 1px)}) {
    @content;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container2 {
  container: recipe / inline-size;
}

.recipe-container {
  padding-top: 0 !important;

  @include media-breakpoint-up('xl') {
    padding-left: 112px !important;
    padding-right: 112px !important;
  }
}

.hero {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include container-up ($md) {
    flex-direction: row;
    padding: 40px 79px 0;
  }

  @include container-up ($lg) {
    padding: 40px 112px 0;
  }

  @include media-breakpoint-up('xl') {
    padding-top: 40px;
    max-width: map-get($grid-breakpoints, 'xl');
  }
}

.heroContent {
  display: flex;
  flex-direction: column;

  @include container-down ($md) {
    padding: 20px 16px 0;
  }

  @include container-up ($md) {
    padding-left: 24px;
  }

  @include container-up ($lg) {
    min-height: 400px;
    padding-left: 40px;
  }
}

.imagewrapper {
  @include media-breakpoint-down('sm') {
    height: 390px;
  }

  @include media-breakpoint-down('xs') {
    height: 250px;
  }

  @include container-up ($md) {
    min-height: 300px;
    min-width: 300px;
  }

  @include container-up ($lg) {
    min-height: 400px;
    min-width: 400px;
  }

  picture > img {
    object-fit: cover;
    height: 100%;
    width: 100%;

    @include container-up ($md) {
      height: 300px;
      width: 300px;
    }

    @include container-up ($lg) {
      height: 400px;
      width: 400px;
    }
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  @include container-up ($md) {
    padding: 40px 79px 0;
  }

  @include container-up ($lg) {
    padding: 40px 112px 0;
  }

  @include media-breakpoint-up('xl') {
    padding-top: 40px;
    max-width: map-get($grid-breakpoints, 'xl');
  }
}

.mealMathsWrapper {
  display: flex;
}

.description {
  margin-top: 20px;
}

.tabs {
  margin-top: 20px;
  margin-bottom: 60px;
}

.logo {
  display: none;
}

.printButton {
  min-width: none;
  margin-top: 20px;
  margin-right: 24px;

  svg {
    height: 16px;
    width: 16px;
  }
}

.rating {
  margin: 20px 0 ;

  > span:first-child {
    margin-top: -3px;
  }
}

.rate {
  text-decoration: underline;
  display: inline-block;
  padding-left: 18px;
}

.rating-details {
  summary {
    display: none;
  }
  margin-top: 60px;
}

.background {
  padding: 2px 0 40px;
  @include extendBackground($colour-scallop-grey);

  &::before {
    z-index: -1;
  }
}

.placeholder {
  min-height: 800px;
}

.recipeLogo {
  width: 48px;
  height: 48px;
  margin-left: 12px;

  @include media-breakpoint-up('md') {
    width: 54px;
    height: 54px;
  }
}

@media print {
  body > div > div > div {
    div[data-testid='site-banner-wrapper'] {
      display: none;
    }
  }

  div[aria-live="polite"] {
    display: none;
  }

  nav {
    display: none;
  }

  .logo {
    display: block;
    margin: 32px 0 30px;
    height: 31px;
    width: 100%;
  }

  .title {
    display: block;
    width: 100%;
    margin: 30px 0;

    h1 {
      font-size: 30px !important;
      line-height: 42px;
    }
  }

  .recipeLogo {
    width: 48px;
    height: 48px;
    margin: 12px;

    @include media-breakpoint-up('md') {
      width: 54px;
      height: 54px;
    }
  }

  .printButton {
    display: none;
  }

  .content {
    padding: 0 20px;
  }

  .description {
    width: 100%;
  }

  .allergens {
    width: 100%;

    span {
      border: 1px solid $colour-primary-grey;
    }
  }

  .rating {
    display: none;
  }

  .rate::after {
    display: none;
  }

  .course {
    width: 100%;

    ul {
      max-width: 100%;
      flex-direction: column;
      margin: 0;

      > li {
        display: flex;
        width: fit-content;

        > span {
          flex-grow: 1;
          padding: 0 2px;
        }
      }
    }
  }

  .printHeading {
    position: static;
    width: auto;
    height: auto;
    margin: 24px 0;
  }

  .tabs {
    display: grid;
    grid-template-columns: 1fr;
    grid-column: 1;
    gap: 32px;
    margin: 0 auto;
    width: 100%;

    > div {
      display: block !important;

      &:first-child {
        display: none !important;
      }

      &:nth-child(2) {
        grid-column: 1/1;
        grid-row: 1/2;
      }

      &:nth-child(3) {
        grid-column: 1/1;
        grid-row: 2/3;
        border-top: 1px solid $colour-mushroom-grey;
      }

      &:nth-child(4) {
        grid-column: 1/1;
        grid-row: 3/4;
        border-top: 1px solid $colour-mushroom-grey;
      }
    }
  }

  .ingredients {
    > div {
      margin-top: 10px;
    }

    ul {
      columns: 2;
    }
  }

  .method {
    ol {
      columns: 2;

      li:first-child {
        margin-top: 0;
      }
    }
  }

  .nutritional {
    > div {
      margin-top: 10px;

      td {
        padding: 0;
      }

      p {
        margin: 0;
      }
    }
  }

  .ratingSummary {
    display: none;
  }

  details[id="rating"] {
    display: none;
  }

  footer {
    display: none;
  }
}

