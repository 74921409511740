@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../../wdx/mixins/typography';

$social-icon-size: 24px;

.socialLinks {
  background: $colour-white;
  border-bottom: 1px solid $colour-mushroom-grey;
  display: flex;
  flex: {
    basis: auto;
    direction: row;
    grow: 0;
    shrink: 0;
    wrap: nowrap;
  }
  justify-content: space-between;
  padding: $grid-vertical-gutter;

  @include media-breakpoint-up('md') {
    justify-content: center;
  }

  @include media-breakpoint-up('lg') {
    border: {
      bottom: 0;
      left: 1px solid $colour-mushroom-grey;
    }
    flex: {
      basis: 25%;
      direction: column;
      grow: 1;
    }
    justify-content: flex-start;
    padding: 0 $grid-vertical-gutter;
  }
}

.socialSectionTitle {
  display: none;

  @include media-breakpoint-up('lg') {
    color: $text-color;
    display: block;
    font: {
      family: $font-family-base;
      size: $font-size-large; // ~18px
      weight: normal;
    }
    margin-top: 0;

    .siteLinks & {
      display: none;
    }
  }
}

.socialLink {
  color: $colour-earl-grey;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0 1 auto;
  margin: 0;
  padding: ($grid-vertical-gutter * .25) 0;

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    .socialIcon {
      path {
        fill: $text-color;
      }
    }

    .socialLinkText {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up('md') {
    margin: 0 $social-icon-size;
  }

  @include media-breakpoint-up('lg') {
    margin: 4px 0;
  }
}

.socialLinkText {
  @include paragraph;
  display: none;

  @include media-breakpoint-up('lg') {
    display: inline;
  }
}
