@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

@mixin all-states {
  &,
  &:active,
  &:focus,
  &:hover {
    @content;
  }
}

@mixin waitrose-font {
  font-family: $font-family-base;
  color: $text-color;
}

@mixin header-font {
  @include waitrose-font;
  font: {
    size: 14px;
    weight: 200;
  }
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

@mixin specialist-shops-font {
  @include header-font;
  font-size: 12px;
}

@mixin customer-service-links {
  @include waitrose-font;
  font-size: 14px;
  font-weight: 500;
}

@mixin sign-in-out-font {
  @include specialist-shops-font;
  font-weight: 400;
}

@mixin header-browse-font {
  @include header-font;

  @include media-breakpoint-up('xl') {
    font-size: 16px;
    letter-spacing: 2px;
  }
}

@mixin shop-font {
  @include header-font;
  @include all-states {
    color: $colour-earl-grey;
    text-decoration: none;
  }
  font-weight: 400;
  letter-spacing: 2px;
}

@mixin site-header-font {
  @include header-browse-font;
  font-weight: 400;
  letter-spacing: 2px;
}
