@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';


.searchHistoryContainer {
  background: $colour-white;
  left: 0;
  outline: none;
  position: absolute;
  text-align: left;
  top: 108px;
  width: 100%;


  @include media-breakpoint-up('md') {
    top: 40px;
    outline: 2px solid $colour-primary-grey;
  }

  @include media-breakpoint-down('sm') {
    &.homepage {
      top: 46px;
      box-shadow: 0 0 0 2px $colour-primary-grey;
    }
  }

  .label {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $colour-oyster-grey;
    font-weight: 300;
    margin: 0 20px;
    padding: 12px 0;

    .clearAll {
      cursor: pointer;
      background: none;
      border: 0;
      box-shadow: none;
      margin: 0;
      padding: 0;
      text-decoration: underline;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 8px;
    padding: 0;
  }

  li {
    display: flex;
    padding: 8px 20px 0;

    &.selected {
      background-color: $colour-focus-blue;
      color: $colour-white;

      .termButton {
        color: $colour-white;
      }

      .removeButton {
        color: $colour-white;
      }
    }
  }

  .searchTerm {
    flex: 12;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .removeButton {
    flex: 1;
    cursor: pointer;
    background: none;
    border: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;

  }
}
