@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '../common';

%icon {
  @extend %header-icon;
  display: none;
  margin-right: 5px;

  @include media-breakpoint-up('md') {
    display: inline;
  }
}

.links {
  @extend %header-links;
  align-items: center;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up('sm') {
    justify-content: center;

    a {
      margin: {
        left: $header-link-margin * .4;
        right: $header-link-margin * .4;
      }
    }
  }

  @include media-breakpoint-up('md') {
    justify-content: initial;

    a {
      margin: {
        left: 0;
        right: $header-link-margin;
      }
    }
  }
}

.customerIcon {
  @extend %icon;
  width: 30px;
}

.shopsIcon {
  @extend %icon;
}
