@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.footer {
  box-shadow: 0 0 7px 1px $colour-primary-grey--alpha-40;
  position: relative;
  z-index: 2;

  &.contentVisibility {
    content-visibility: auto;
    contain-intrinsic-size: auto 100vw auto 698px;

    @include media-breakpoint-up('md') {
      contain-intrinsic-size: auto 100vw auto 638px;
    }
  
    @include media-breakpoint-up('lg') {
      contain-intrinsic-size: auto 100vw auto 630px;
    }
  }
}

.linksLists {
  display: flex;
  flex: {
    direction: column;
    wrap: nowrap;
  }
  padding-top: $grid-vertical-gutter * 1.625;

  @include media-breakpoint-up('lg') {
    flex-direction: row;
    justify-content: center;
    padding: {
      bottom: $grid-vertical-gutter * 2.8125;
      left: $grid-vertical-gutter;
      right: $grid-vertical-gutter;
      top: $grid-vertical-gutter * 2.375;
    }
  }
}
