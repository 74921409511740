@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../ingredients/styles/focus';
@import '../../../../ingredients/styles/spacing';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '../../../wdx/mixins/typography';
@import '../../../Button/styles';
@import '../mixins';

$icon-size: $ingredients-unit * 7;

.accountActionWrapper {
  margin-right: 16px;
  margin-left: 6px;
  position: relative;
  width: auto;
}

.accountActions {
  margin-bottom: 0;
  padding: 0;
}

.button {
  align-items: center;
  @include site-header-link;
  background: $colour-transparent;
  border: 0;
  border-radius: 0;
  color: $colour-primary-grey;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  padding-top: 4px;
  height: $ingredients-gutter * 2;
  line-height: $ingredients-gutter * 2;
  z-index: 1;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: thin dotted;
    outline-offset: -1px;
    text-decoration: none;
  }

  &::after {
    bottom: -1px;
  }

  .iconWrapper {
    position: relative;
  
    &.CANotPresent {
      &::after {
        position: absolute;
        right: 5px;
        top: 1px;
        background: $colour-waitrose-error-red;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: solid 2px $colour-white;
        content: '';
      }
    }
  }
}

.buttonIcon {
  margin-right: $ingredients-unit*2.5;
  margin-bottom: -$ingredients-unit*0.5;
}

.chevronIcon {
  width: 2em;
  margin-bottom: -$ingredients-unit*0.5;

  svg {
    height: 20px;
  }
}

.accountPopup {
  background: $colour-white;
  box-shadow: 0 3px 8px 0 $colour-earl-grey;
  position: absolute;
  right: 0;
  text-align: left;
  top: calc(100% + 1px);
  width: 252px;
  z-index: 2;

  li {
    border-top: 1px solid $colour-mushroom-grey;
    list-style: none;
    padding: $ingredients-unit * 2;
    position: relative;

    &:first-child,
    &:last-child {
      border-top: 0;
    }

    a {
      @extend %text-button;
      @include focus;
      @include paragraph;
      align-items: center;
      display: flex;
      font-weight: 500;
      margin: 0;
      text: {
        align: left;
        transform: none;
      }
      width: 100%;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.accountMenuIcon {
  display: inline-block;
  line-height: initial;
  margin-right: $ingredients-gutter * .5;

  svg {
    height: 100%;
    width: 100%;
  }
}

.iconNoCAWarning {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: $colour-waitrose-error-red;
}

.signOut {
  padding: $ingredients-gutter * .5;
}

.signOutButton {
  background: $colour-white;
  border: 1px solid;
  cursor: pointer;
  font-weight: 500;
  padding: $ingredients-unit * 2;
  width: 100%;
}
