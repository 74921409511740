@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../components/SiteHeader/variables';
@import '../../styles/tools/mixins';
@import '../../styles/helpers/component-visibility';

html,
body,
:global(#content),
:global(#content) > div {
  height: 100%;
}

.appWrapperMinimal {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

:global(.hasSitePinBar) {
  margin-bottom: 50px;
}

@include media-breakpoint-up('md') {
  :global(.hasSitePinBar) {
    margin-bottom: 125px;
  }
}

:global(.bodyNoScroll) {
  overflow: hidden;
}

:global(.ReactModal__Overlay) {
  z-index: $zindex-modal;
}

%main {
  position: relative;
}

.appMain {
  @extend %main;
  min-height: 40vh
}

.appMainMinimal {
  @extend %main;
  background-color: $colour-eggshell-grey;
  flex-grow: 1;
}
