@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

$checkout-custom-breakpoint: (
  'xxs': 368px,
);

%logo {
  @include media-breakpoint-up('lg') {
    width: 200px;
    height: 43px
  }

  .minimal & {
    width: 286px;
  }
}

.logoRegular {
  @extend %logo;
  display: none;

  @include media-breakpoint-up('md') {
    display: inline;
  }

  .minimal & {
    display: inline;
    width: 318px;
  }
}

.checkout {
  height: 44px;

  @include media-breakpoint-up('xxs', $checkout-custom-breakpoint) {
    height: 61px;
  }

  @include media-breakpoint-up('md') {
    height: 68px;
  }
}

.homepage {
  display: flex;
  height: 61px;
  width: 285px;

  @include media-breakpoint-up('sm') {
    width: 307px;
  }

  @include media-breakpoint-up('md') {
    height: 68px;
    width: 317px;
  }

  @include media-breakpoint-up('lg') {
    height: 85px;
    width: 398px;
  }
}

.logoSmall {
  @extend %logo;
  width: 87px;
  display: inline;

  @include media-breakpoint-up('lg') {
    display: none;
  }

  @include media-breakpoint-up('md') {
    display: inline;
    height: 26px;
    width: 104px;
  }

  .minimal & {
    width: 208px;

    @include media-breakpoint-up('sm') {
      width: 286px;
    }
  }
}

.minimal {
  display: block;
  height: 28px;
  width: 318px;

  @include media-breakpoint-up('md') {
    display: inline;
    height: 45px;
  }

  @include media-breakpoint-up('lg') {
    height: 50px;
  }
}
