@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../variables';

.overlay {
  position: fixed;
  top: 51px;
  left: 0;
  background: $colour-primary-grey--alpha-70;
  height: 100%;
  width: 0;

  opacity: 0;
  transition: opacity $slide-duration, width 0s $slide-duration, z-index 0s $slide-duration;
  z-index: -1;
}

.overlayActive {
  composes: overlay;
  opacity: 1;
  transition: opacity $slide-duration;
  width: 100%;
}
