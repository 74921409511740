@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../wdx/mixins/typography';

.header {
  background-color: $colour-scallop-grey;
}

.content {
  background-color: $colour-scallop-grey;

  &:empty {
    height: 100vh;
  }

  section:first-child > header {
    border-top: none;
  }
}
