@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../wdx/common';
@import '../MegaMenu/common';

.legend {
  color: $text-color;
  font-weight: 200;

  margin-left: $padding-base-horizontal * 2;
}

.wrapper {
  @include specialist-shops-font;
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
}

.link {
  color: $text-color;
  font-weight: 400;

  &:focus {
    outline: {
      color: $colour-focus-blue;
      offset: $focus-outline-width;
      style: solid;
      width: $focus-outline-width;
    }
  }

  &:hover {
    text-decoration: none;
  }

  @include media-breakpoint-up('lg') {
    display: inline-block;
    line-height: 1;
    margin-left: $grid-vertical-gutter*2;
    position: relative;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
     }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &.oldStyles {
      font-weight: 200;
    }
  }

  @include media-breakpoint-up('xl') {
    margin-left: $grid-vertical-gutter*2;
  }
}
