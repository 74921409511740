@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../variables';

.promotion {
  @include media-breakpoint-up('sm') {
    margin-top: 20px;

    .entertaining & {
      padding-bottom: $grid-vertical-gutter;
    }
  }
}
