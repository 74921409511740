@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';

$border-color: 1px solid $colour-mushroom-grey;

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 0;
  border-bottom: none;

  @include media-breakpoint-up('md'){
    margin-bottom: 20px;
    border-bottom: $border-color;
  }
}

.withExtraColumnContainer {
  margin-bottom: 0;
  border-bottom: none;

  @include media-breakpoint-up('lg'){
    margin-bottom: 20px;
    border-bottom: $border-color;
  }
}

.list {
  padding: 20px 0 10px;

  flex: {
    grow: 1;
  }
  list-style: none;

  span {
    &:first-child {
      &::after {
        content: ' ';
        white-space: pre;
      }
    }  
  }

  li {
    border-bottom: $border-color;
    padding: 4px 0;
  }

  @include media-breakpoint-up('md'){
    li {
      border-bottom: 0;
      padding: 0;
    }
  }
}

@mixin horizontal-list($size) {
  @include media-breakpoint-up($size) {
    display: flex;
    flex-direction: row;

    li {
      width: 6.65em;

      span {
        &:first-child {
          &::after {
            content: '\A';
            white-space: pre;
          }
        }
      }
    }
  }
}

.horizontal {
  @include horizontal-list('md')
}

.withExtraColumn {
  @include horizontal-list('lg');

  li {
    &:last-child {
      flex: auto;
    }
    border-bottom: $border-color;
    padding: 4px 0;
  }

  @include media-breakpoint-up('lg'){
    li {
      border-bottom: 0;
      padding: 0;
    }
  }
}

.servingStringExtraLong {
    padding-right: 1em;
    min-width: 9em;
}