@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../../../ingredients/styles/focus';

.textarea {
  @include focus-inset;

  border: {
    color: $colour-oyster-grey;
    width: 1px; // note: must match constant in Textarea component
  };
  color: $text-color;
  font-family: $font-family-base;
  padding: 12px;
  width: 100%;

  &::placeholder {
    color: $colour-chia-grey;
    font-style: italic;
  }

  .error & {
    border-color: $colour-offer-red;
  }
}

.list {
  line-height: 1.5em;
}
