@import '~@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins";
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables";

$ITEM_CONTROLS_WIDTH: 216px; // Needed to match itemControls width

.wrapper {
  @include media-breakpoint-down('md') {
    width: 100%;
  }

 .duplicate {
    @include media-breakpoint-up('md') {
      width: $ITEM_CONTROLS_WIDTH;
    }
  }

  .conflict {
    @include media-breakpoint-up('md') {
      width: $ITEM_CONTROLS_WIDTH;
    }

    .conflictItemControl {
      padding: 0;
      background: none;
      margin-bottom: 0;
      line-height: 1.5;

      > div > div {
        text-align: center;
        @include media-breakpoint-up('md') {
          text-align: left;
        }
      }
    }
  }

  .itemControls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .uom {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }

  .quantityInputs {
    display: flex;
    gap: 8px;

    .inputWrapper {
      display: flex;
      border: 1px solid $colour-primary-grey;
      font-size: $font-size-base;
      font-weight: 400;
      height: 40px;
      width: 62px;
      padding: 0 6px;
      flex-grow: 1;
      cursor: text;

      &.hide {
        display: none;
      }

      &:focus-within {
        box-shadow: none;
        outline-color: $colour-focus-blue;
        outline-offset: 4px;
        outline-style: solid;
        outline-width: 4px;
      }

      .inputUom {
        display: none;
      }

      &.showUom {
        & .inputUom {
          display: flex;
          align-items: center;
          height: 100%;
          text-align: left;
          color: $colour-earl-grey;
        }
      }

      .input {
        appearance: textfield;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        font-size: inherit;
        height: 100%;
        margin: 0;
        text-align: left;
        width: 100%;
        outline: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          appearance: none;
          margin: 0;
        }
      }
    }
  }

    .displayPrice {
      min-width: 110px;
      text-align: right;
      padding-left: 8px;

      .displayPriceQualifier {
        font-weight: 100;
      }
    }
  }
}