@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../../SiteHeader/variables';

$animation-timing: .2s;

%link-button {
  color: $text-color;
  text-decoration: underline;
}

.multiSearch {
  @extend %link-button;
  appearance: none;
  border: 0;
  background: 0;
  font: {
    family: Waitrose;
    size: $font-size-base;
    weight: 200;
  }
  height: 38px;
  position: absolute;
  line-height: $font-size-large;
  padding: 0;
  right: 53px;
  transition: opacity $animation-timing linear;
  top: 0;
  z-index: 2;

  &:active {
    outline: 0;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &:focus {
    outline: 4px solid $colour-focus-blue;
  }
}

.chevronRightIcon {
margin: 4px;
}

.mobileMultiSearch {
  background-color: transparent;
  border: 0;
  font-weight: 100;
  padding: 0;
  text-decoration: underline;
}
