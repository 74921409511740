@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../../styles/settings/variables';
@import '../../wdx/mixins/button';

.button {
  @include wdx-button-base;
  cursor: pointer;
  display: inline;
  margin: 0;
  overflow: hidden;
  padding: ($ingredients-unit * 2) ($ingredients-unit * 4);
  text-overflow: ellipsis;
}

.input {
  opacity: 0;
  width: 0;
}

.multiSearchButton {
  display: flex;
  max-width: 350px;
  white-space: nowrap;
}

.selected {
  background-color: $colour-waitrose-grey;
  color: $colour-white;
}
