@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';

%focus-first-child {
  &:focus {
    outline: none;

    & > :first-child {
      outline: dotted 1px $text-color;
    }
  }
}

%browser-initial-focus {
  outline: 1px dotted $colour-primary-grey;

  @media (-webkit-min-device-pixel-ratio: 0) {
    outline: auto -webkit-focus-ring-color 5px;
  }
}
