@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import '../../../components/SiteHeader/variables';

.content {
  min-height: calc(100vh - $header-height);
  margin: auto;

  > h1 {
    margin-top: 30px;
    margin-bottom: 36px;

    @media (max-width: 375px) {
      margin-top: 16px;
      margin-bottom: 26px;
    }

    @include media-breakpoint-up('lg') {
      margin-bottom: 52px;
    }
  }
}

.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid $colour-oyster-grey;
  background-color: $colour-white;
  padding: 20px 16px;
  z-index: 3;
  width: 100vw;
  display: flex;
  justify-content: center;

  a, button {
    @include media-breakpoint-down('sm') {
      width: 100%;
    }
  }
}

.stepTitle {
  text-align: center;
}
