@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.removeButton {
  margin-top: 9px;

  @include media-breakpoint-up('sm') {
    margin-bottom: 7px;
  }
}
