@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../../wdx/common';

$strip-row-vertical-padding: $ingredients-unit;
$strip-value-horizontal-padding: $ingredients-unit;

.disappear {
  @include media-breakpoint-only('xs') {
    opacity: 0;
    transition: opacity $wdx-transition-time-fast ease-in, visibility 0s $wdx-transition-time-fast;
    visibility: hidden;
  }
}

.outer {
  $border-style: 1px solid $colour-mushroom-grey;

  background-color: $colour-white;
  border: {
    bottom: $border-style;
    top: $border-style;
  }

  @include media-breakpoint-only('xs') {
    transition: opacity $wdx-transition-time-fast ease-in, visibility $wdx-transition-time-fast 0s;
  }
}

.strip {
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: ($ingredients-unit * 3) 0;
}

.value {
  margin: $strip-row-vertical-padding $strip-value-horizontal-padding;
  list-style: none;
}
