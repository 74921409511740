@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';

@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.header {
  display: grid;
  grid-template-areas:
    "logo"
    "info";
  grid-template-rows: auto 52px;
  align-items: baseline;
  height: 160px;
  background: $colour-white;
  border-bottom: 1px solid $colour-mushroom-grey;
  color: $colour-earl-grey;


  @include media-breakpoint-up("xl") {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "secure logo contact";
    justify-content: space-between;
    align-items: center;
    padding-inline: 16px;
  }

  [class*='icon'] {
    display: none;
  }

  .logoWrapper {
    grid-area: logo;
    display: flex;
    justify-content: center;
    height: 100%;
    border-bottom: 1px solid $colour-mushroom-grey;

    @include media-breakpoint-up("xl") {
      border-bottom: none;
    }

    .logo {
      width: 230px;
      height: auto;
      margin: 0 !important;

      @include media-breakpoint-up("md") {
        width: 275px;
      }

      @include media-breakpoint-up("xl") {
        width: 392px;
      }
    }
  }

  .infoWrapper {
    grid-area: info;
    display: flex;
    justify-content: center;
    gap: 16px;
    height: 52px;

    @include media-breakpoint-up("md") {
      gap: 24px;
    }

    @include media-breakpoint-up("xl") {
      display: contents;
    }

    .secureWrapper {
      grid-area: secure;
      display: flex;
      align-items: center;

      .secure {
        margin: 0 0 0 8px;
        text-transform: uppercase;
        font-size: 12px;

        @include media-breakpoint-up("md") {
          font-size: 14px;
        }
      }
    }

    .contactWrapper {
      grid-area: contact;
      justify-self: flex-end;
      display: flex;
      align-items: center;

      .contact {
        margin-left: 8px;
        color: $colour-earl-grey;

        span {
          font-size: 12px;

          @include media-breakpoint-up("md") {
            font-size: 16px;
          }
        }

      }
    }
  }
}