@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/tools/mixins';
@import '../../../../styles/settings/variables';
@import 'ingredients/styles/focus';
@import '../../utils/utils.scss';

.recipe {
  position: relative;
}

.a {
  display: block;
  height: 100%;

  > div {
    display: block;
    border: 1px solid $colour-oyster-grey;

    &:hover {
      border: 1px solid $colour-primary-grey;
    }

    > div {
      &:first-child {
        height: 221px;
      }

      &:last-child {
        height: calc(100% - 221px);
      }
    }
  }

  @include focus;

  &:hover,
  &:focus {
    text-decoration: none;

    .title {
        text-decoration: underline;
      }
    }
}

.clock {
  color: $colour-earl-grey;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
}

.titleRating {
  min-height: 62px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .ratingSummary {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    margin-top: 6px;
    max-height: 24px;

    @include media-breakpoint-up('sm') {
      margin-top: 10px;
    }
  }
}
