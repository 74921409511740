@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 24px 16px;

  > h1 {
    margin-top: 24px !important;
  }

  @include media-breakpoint-up('lg') {
    padding: 54px 108px;
  }
}

.label {
  margin-bottom: 8px !important;
  border: 1px solid $colour-earl-grey;
  padding: 8px 12px;
  width: 100%;
  @include media-breakpoint-up('md') {
    width: 360px;
  }
}

.stepHeading {
  margin-bottom: 8px !important;
}


.alertWrapper {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  @include media-breakpoint-up('md') {
    width: 60%;
  }
  @include media-breakpoint-up('lg') {
    width: 50%;
  }
}
