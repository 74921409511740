@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../../SiteHeader/variables';
@import '../common';
@import '../../../ingredients/styles/focus';

$title-height: 45px;

.close {
  @include focus;
  background: transparent;
  border: 0;
  cursor: pointer;
  line-height: 0;
  margin: $ingredients-unit * 4 $ingredients-unit * 3.5 $ingredients-unit * 2.5 0;
  outline-color: $colour-focus-blue;
  overflow: hidden;
  position: absolute;
  padding: 0;
  right: 0;
  top: 0;
  width: fit-content;

  i {
    padding: 0;

    svg {
      height: 28px;
      width: 28px;
    }
  }
}

.closed {
  overflow: hidden;
  display: block;
  visibility: hidden;
}

.closeLink {
  @include focus-inset;
  @include pseudo-cross($length: 20px, $thickness: 1px, $colour: $colour-primary-grey);
  cursor: pointer;
  display: inline-flex;
  background: transparent;
  font-size: 24px;
  height: 100%;
  overflow: hidden;
  position: absolute;
  padding: 0;
  right: 0;
  top: 0;
  width: $title-height;

  i {
    padding: 0;

    svg {
      height: 28px;
      width: 28px;
    }
  }
}

.hideSubMenu {
  div {
    &:not(:first-child) {
      opacity: 0;
      transition: opacity $slide-duration;
    }
  }
}

.loading {
  padding: 20px;
}

.menuCloseWrapper {
  position: absolute;
}

.menus {
  display: flex;
  flex-direction: row;
  overflow: {
    x: hidden;
    y: auto;
  }
  -webkit-overflow-scrolling: touch; // smooth scroll on iOS
  width: 24%;

  &:focus {
    outline: thin dotted;
  }

  &.activeLevel {
    width: 100%;
  }
}

.nav {
  @include menu-max-height;

  background-color: $colour-eggshell-grey;
  left: initial;
  max-width: 0;
  opacity: 0;
  position: absolute;
  right: initial;
  top: 108px;
  transition: opacity $slide-duration;
  user-select: none;
  z-index: -1;

  &.open {
    border: 1px solid $colour-oyster-grey;
    display: flex;
    max-width: 1130px;
    opacity: 1;
    transition: opacity $slide-duration;
    width: calc(100% - 32px);
    z-index: 0;
    visibility: visible;
  }
}

.scrolled {
  top: 50px;
}

.banner {
  width: 24%;
  margin-top: 60px;
  margin-left: 23px;
  margin-right: 23px;

  :global(#cms-banner-content) > * {
    width: 100%;
  }

  [id^='trading-asset-grid'] {
    padding: 0;
  }
}
