@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import './typography';

@mixin wdx-button-base {
  @include paragraph('medium');

  align-items: center;
  border: 1px solid $colour-primary-grey;
  color: $text-color;
  display: inline-flex;
  justify-content: center;
  min-height: 40px;
  padding: 8px;
  text-align: center;
  user-select: none;
}

@mixin wdx-button-primary {
  @include wdx-button-base;
  color: $colour-white;
  background: $colour-waitrose-grey;
  border-color: $colour-waitrose-grey;

  &:hover {
    color: $colour-white;
    background: $colour-earl-grey;
    border-color: $colour-earl-grey;
    text-decoration: none;
  }

  &:active {
    background: $colour-primary-grey;
    border-color: $colour-primary-grey;
  }

  &:focus {
    color: $colour-white;
    text-decoration: none;
    box-shadow: 0 0 0 4px $colour-focus-blue;
    outline: 0;
  }
}

@mixin wdx-link-button {
  &,
  &:hover,
  &:focus {
    @include wdx-button-base;
    text-decoration: none;
  }
}

@mixin wdx-button-secondary {
  @include wdx-button-base;
  background: $colour-white;
  color: $colour-primary-grey;

  &:hover {
    background: $colour-white;
    color: $colour-primary-grey;
    box-shadow: inset 0 0 0 1px $colour-primary-grey;
  }

  &:active {
    background: $colour-scallop-grey;
    color: $colour-primary-grey;
    border-color: $colour-primary-grey;
    box-shadow: inset 0 0 0 1px $colour-primary-grey;
  }

  &:focus {
    background: $colour-white;
    color: $colour-primary-grey;
    box-shadow: 0 0 0 4px $colour-focus-blue;
    outline: 0;
  }

  &:disabled {
    background: $colour-white;
    border-color: $colour-chia-grey;
    color: $colour-chia-grey;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }
}
