@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

.smq {
  width: 100%;
  height: 100%;

  @include media-breakpoint-between('xs', 'sm') {
    display: var(--xs, contents);
  }
  @include media-breakpoint-between('sm', 'md') {
    display: var(--sm, contents);
  }
  @include media-breakpoint-between('md', 'lg') {
    display: var(--md, contents);
  }
  @include media-breakpoint-between('lg', 'xl') {
    display: var(--lg, contents);
  }
  @include media-breakpoint-up('xl') {
    display: var(--xl, contents);
  }
}