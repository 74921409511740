@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '@johnlewispartnership/wtr-ingredients/dist/foundations/colours';

.noMargin {
  margin: 0;
}

.offerDescription {
  color: $colour-offer-red;

  &.green{
    color: $colour-corporate-green;
  }

  &.withBackground {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px 8px 16px;
    overflow: hidden;

    @include media-breakpoint-down('xs') {
      padding: 8px 8px 8px 12px;
    }

    .chevronIcon {
      align-self: center;
      transition: transform 0.3s ease;
      flex: none;

      @include media-breakpoint-down('xs') {
        display:none
      }
    }

    &:hover .chevronIcon {
      transform: translate(4px);
    }

    & > span:first-child{
      padding-right: 8px;
      flex-basis: fit-content;
    }
  }

  em {
    display: inline-block;
    font-style: normal;
    padding-left: 4px;
    text-decoration: none;
  }
  
  .withStrikethrough {
      color: $colour-primary-grey;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: -0.25rem;
      text-decoration-skip-ink: none;
  }
}

.underline {
  text-decoration: underline;
}


