@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '../../../styles/settings/variables';
@import '../../wdx/mixins/typography';

%popover {
  border: 1px solid $colour-oyster-grey;
  border-radius: $ingredients-unit;
  box-shadow: 0 0 ($ingredients-unit * 2.5) ($ingredients-unit * -.5) $colour-primary-grey--alpha-40;
  padding: $ingredients-gutter;
  position: relative;
}

%my-waitrose-icon {
  background: {
    color: $colour-white;
    image: url('../../MyWaitrose/mywaitrose-icon.png');
    repeat: no-repeat;
  }
}

.wrapper {
  @extend %popover;
  @extend %my-waitrose-icon;
  background: {
    position: 123px 17px;
    size: 50px auto;
  }
  padding-top: 56px;
  text-align: center;
  width: 298px;
  z-index: 2;
}

.closeButtonContainer {
  position: absolute;
  right: 10px;
  top: 8px;

  button {
    min-height: auto;
    min-width: auto;
    border: 0;
    padding: 0;
    max-height: 32px;
    max-width: 32px;
    box-shadow: none;

    svg {
      filter: none;
    }
  }
}

.title {
  @include paragraph-sub('bold');
  color: $colour-offer-red;
}

.text {
  @include paragraph-sub;
}

.link {
  @include paragraph-sub('medium');
  text-decoration: underline;
}
