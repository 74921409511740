@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';

$vertical-margin: $grid-vertical-gutter * .25;

%modal {
  background-color: $colour-white;
  box-shadow: 0 3px 7px 0 $colour-primary-grey--alpha-40;
  color: $text-color;
  cursor: default;
  font-family: $font-family-base;
  left: 50%;
  max-width: 544px;
  overflow: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  -webkit-overflow-scrolling: touch;
}

%modal-overlay {
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: {
    x: hidden;
    y: auto;
  }
  position: fixed;
  right: 0;
  top: 0;
}

%modal-overlay-open {
  z-index: 6;
}

%modal-close-button {
  background-color: $colour-white;
  border: 0;
  color: $colour-chia-grey;
  cursor: pointer;
  margin: $vertical-margin 5px;
  padding-top: 5px;
  position: absolute;
  right: 0;
  top: 0;

  &:after {
    content: '\e912';
    font: {
      family: 'WaitroseGlyph';
      size: $font-size-base * 1.5;
    }
  }
}
