@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '../common';

.icon {
  @extend %header-icon;
  margin: {
    top: 4px;
  }

  path {
    fill: $colour-earl-grey;
  }
}

.account {
  @extend %header-links;
  align-items: center;
  display: flex;
}

.signOut {
  @extend %header-links;
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;

  &,
  &:hover,
  &:focus {
    color: $colour-earl-grey;
  }

  &:hover,
  &:visited,
  &:active {
    text-decoration: underline;
  }

  &:focus {
    outline: 1px solid $colour-primary-grey;
  }
}

.signIn {
  color: $colour-earl-grey;
  position: relative;
  display: inline-block;
  padding: 0 11px;
  letter-spacing: 2px;
  line-height: 24px;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
    color: $colour-primary-grey;
  }

  &:after {
    border-right: 1px solid $colour-oyster-grey;
    bottom: 0;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 6px;
    height: 12px;
  }

  &:first-of-type {
    margin-left: -4px;
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }

  article > & {
    display: none;
  }

  i {
    height: 24px;
  }
}
