@import '../settings/variables';
@import '../tools/mixins';

$screen-sizes: map-keys($grid-breakpoints);

:global {
  @each $screen-size in $screen-sizes {
    @include responsive-invisibility('.visible-#{$screen-size}');

    .visible-#{$screen-size}-block,
    .visible-#{$screen-size}-flex,
    .visible-#{$screen-size}-inline,
    .visible-#{$screen-size}-inline-block {
      display: none;
    }

    @include media-breakpoint-only($screen-size, $grid-breakpoints) {
      @include responsive-visibility('.visible-#{$screen-size}');

      .hidden-#{$screen-size} {
        display: none !important;
      }

      .visible-#{$screen-size}-block {
        display: block !important;
      }

      .visible-#{$screen-size}-flex {
        display: flex !important;
      }

      .visible-#{$screen-size}-inline {
        display: inline !important;
      }

      .visible-#{$screen-size}-inline-block {
        display: inline-block !important;
      }
    }
  }

  // visuallyhidden class taken from html5-boilerplate project
  // (https://github.com/h5bp/html5-boilerplate)
  //
  // Hide only visually, but have it available for screen readers:
  // https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
  //
  // 1. For long content, line feeds are not interpreted as spaces and small width
  //    causes content to wrap 1 word per line:
  //    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
  //
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; // 1
  }

  .no-print {
    @media print {
      display: none !important;
    }
  }
}
