@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';

$button-border: 1px solid $colour-scallop-grey;

%control {
  background: $colour-white;
  bottom: 0;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .2s ease;
  width: 4px * 12;

  @media screen and (any-hover: none) {
    display: none;
  }

  &.hover,
  &:hover {
    opacity: 1;

    @media screen and (any-hover: none) {
      opacity: 0;
    }
  }
}

%left-control {
  @extend %control;
  border-right: $button-border;
  left: $grid-vertical-gutter * -1;
  right: auto;

  @include media-breakpoint-up('md') {
    left: $grid-vertical-gutter * -2;
  }
}

%right-control {
  @extend %control;
  border-left: $button-border;
  left: auto;
  right: $grid-vertical-gutter * -1;

  @include media-breakpoint-up('md') {
    right: $grid-vertical-gutter * -2;
  }
}

.leftControl {
  @extend %left-control;
  display: flex;

  &Off {
    @extend %left-control;
    display: none;
  }
}

.rightControl {
  @extend %right-control;
  display: flex;

  &Off {
    @extend %right-control;
    display: none;
  }
}

.icon {
  display: flex;
  justify-content: center;
  
  path {
    fill: $text-color;
  }
}
