@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../../ingredients/styles/focus';
@import '../../../styles/settings/variables';
@import '../../wdx/common';
@import '../variables';
@import './mixins';

$icon-size: $ingredients-unit * 8;

%links {
  @include focus-inset;
  display: inline-block;
  line-height: $links-height;
  margin-right: 20px;
  margin-top: 1px;
  padding: 0 4px;
  position: relative;
  z-index: 1;

  @include media-breakpoint-up('xl') {
    margin-right: 32px;
  }

  &:focus {
    text-decoration: none;
    outline-offset: -1px;
  }

  &:hover {
    text-decoration: none;
  }
}

%site-header-icon {
  color: $colour-primary-grey;
  display: inline-block;
  line-height: initial;
  margin-bottom: $ingredients-unit;
  vertical-align: middle;
  width: $icon-size;

  svg {
    height: 100%;
    width: 100%;
  }
}

.siteHeaderLinks {
  display: flex;
}

.primaryLinks {
  display: flex;
  flex: 1 0 auto;
  flex-wrap: nowrap;
  margin-left: 16px;

  > :nth-child(n + 7) {
    display: none;
  }
}

.secondaryLinks {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  margin-right: -32px;
}

.links {
  @extend %links;
  @include site-header-link;
}

.offers {
  @extend %links;
  @include site-header-link($colour-offer-red);
  color: $colour-offer-red;
  font-weight: 600;

  &:hover {
    color: $colour-offer-red;
  }

  &:focus {
    color: $colour-offer-red;
  }
}

.favouritesIcon {
  @extend %site-header-icon;

  margin: {
    right: 2px;
    top: 0;
  }
}

.ordersIcon {
  @extend %site-header-icon;

  margin: {
    right: 2px;
    top: -2px;
    bottom: -1px;
  }

  svg {
    height: 28px;
  }
}

.accountIcon {
  @extend %site-header-icon;

  margin: {
    right: 3px;
    top: 1px;
  }
}
