@import '~@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins";
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables";

.wrapper {
  padding: 0 16px 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @include media-breakpoint-up('md') {
    padding: 0 20px 12px;
    margin-bottom: 100px;
  }
  
  .ingredientGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $colour-waitrose-grey;
    height: 48px;
    margin: 12px 0;

    > div {
      display: flex;
      align-items: center;
      color: $colour-white;
      padding: 12px 20px;
      height: 24px;
    }
  }

  .storeCupboardGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $colour-mushroom-grey;
    height: 48px;
    margin: 12px 0;

    > div {
      display: flex;
      align-items: center;
      color: $colour-primary-grey;
      padding: 12px 20px;
      height: 24px;
    }
  }
}