.method {
  ol {
    counter-reset: method;
    padding: 0;

    li {
      display: flex;

      &::before {
        counter-increment: method;
        content: counter(method);
        line-height: 1.5;
        position: absolute;
        left: 0;
      }
    }
  }

  li {
    position: relative;
  }

  h3 {
    margin: 40px 0 0;
  }
}

.steps {
  list-style: none;

  > li {
    margin-top: 40px;
    padding-left: 0;

    p {
      text-indent: 28px;
    }
  }
}

.add {
  ol {
    li {
      padding-left: 28px;
    }
  }
}
