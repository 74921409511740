@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

$app-banner-background-color: $colour-scallop-grey;
$app-banner-box-shadow-color: $colour-chia-grey;
$app-banner-link-color: $colour-informative-blue;
$app-banner-text-color: $colour-earl-grey;
$app-banner-title-color: $colour-primary-grey;

.appBanner {
  background-color: $app-banner-background-color;
  box-shadow: 1px 0 4px $app-banner-box-shadow-color;
  display: flex;
  align-items: center;
  font-family: Helvetica, sans, sans-serif;
  padding: 10px 16px;
  margin-bottom: 4px;

  .close {
    flex-basis: 10%;

    button {
      background: none;
      border: 0;
      padding: 0;
    }

    i {
      font-size: 10px;
      color: $app-banner-text-color;
    }
  }

  .icon {
    flex-basis: 10%;
  }

  .info {
    flex-basis: 80%;
    margin: 0;
    padding: 10px;
    color: $app-banner-text-color;
    font-size: 12px;
    letter-spacing: .5px;

    @include media-breakpoint-up('md') {
      flex-basis: 20%;
    }

    .appName {
      color: $app-banner-title-color;
      font-size: 14px;
    }

    dt {
      display: none;
    }
  }

  .openLink {
    a {
      color: $app-banner-link-color;
      letter-spacing: .22px;
      text-decoration: none;
    }
  }
}

@media print {
  .appBanner {
    display: none;
  }
}
