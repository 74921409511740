@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../../wdx/mixins/typography';

.appLinks {
  background: $colour-scallop-grey;
  display: flex;
  flex: {
    direction: row;
  }
  justify-content: center;
  align-items: center;
  padding: $grid-vertical-gutter;

  @include media-breakpoint-up('lg') {
    background: $colour-white;
    border-left: 1px solid $colour-mushroom-grey;
    align-items: flex-start;
    flex: {
      direction: column;
    }
    justify-content: flex-start;
    padding: 0 $grid-vertical-gutter;
    margin-top: 30px;
  }
}

.appsSectionTitle {
  color: $text-color;
  font: {
    family: $font-family-base;
    size: $font-size-very-small;
    weight: normal;
  }
  margin-top: 0;

  @include media-breakpoint-up('lg') {
    font: {
      family: $font-family-base;
      size: $font-size-large;
      weight: normal;
    }
  }
}

.appLink {
  color: $colour-earl-grey;
  cursor: pointer;
  margin-left: $ingredients-gutter;
  padding: ($grid-vertical-gutter * .25) 0;

  &:active,
  &:focus {
    outline: 0;
  }

  @include media-breakpoint-up('lg') {
    margin: 0;
  }
}

.appLinkText {
  @include paragraph;
  display: none;
  margin-left: ($grid-vertical-gutter * .25);

  @include media-breakpoint-up('lg') {
    display: inline;
  }
}

.icon {
  & svg {
    height: 28px;
    width: 97px;
  }
}
