@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request: h5bp.com/r
// ==========================================================================

@media print {
  *,
  *:before,
  *:after {
    background: $colour-transparent !important;
    color: $colour-primary-grey !important; // Black prints faster: h5bp.com/s
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a {
    &,
    &:visited {
      text-decoration: underline;
    }

    &[href] {
      &:after {
        content: ' (' attr(href) ')';
      }
    }

    // Don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
    &[href^='#'],
    &[href^='javascript:'] {
      &:after {
        content: '';
      }
    }
  }

  abbr {
    &[title] {
      &:after {
        content: ' (' attr(title) ')';
      }
    }
  }

  pre,
  blockquote {
    border: 1px solid $colour-chia-grey;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  // Bootstrap specific changes start

  .label {
    border: 1px solid $colour-primary-grey;
  }

  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: $colour-white !important;
    }
  }

  // Bootstrap specific changes end
}
