@import '../../MegaMenu/common';

.link {
  color: $text-color;
  font-weight: 400;

  &:hover {
    text-decoration: none;
  }

  @include media-breakpoint-up('lg') {
    display: inline-block;
    font-weight: 400;
    line-height: 1;
    padding: 0 $grid-vertical-gutter;
    position: relative;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up('xl') {
    padding: 0 $grid-vertical-gutter;
  }
}
