@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

@mixin trolley-value-rect($left, $top, $width, $height) {
  height: $height;
  left: $left;
  top: $top;
  width: $width;
}

.icon {
  color: $colour-primary-grey;
}

.value {
  @include trolley-value-rect(12px, 9px, 22px, 16px);
  align-items: center;
  color: $colour-primary-grey;
  display: inline-flex;
  font: {
    family: $font-family-base;
    size: 10px;
    weight: 400;
  }
  justify-content: center;
  position: absolute;

  @include media-breakpoint-up('md') {
    @include trolley-value-rect(-0.5px, -9px, 19px, 16px);
    font-size: 8px;
  }
}

.highlight {
  color: $colour-waitrose-green;
}

.trolleySize {
  display: inline-block;
  position: relative;
}
