@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

.privacyModalHeader {
  font-size: $font-size-large;
  font-weight: normal;
  text-align: center;
}

.waitroseCookiesLink {
  text-decoration: underline;
}

.modal {
  cursor: default;
  font-size: $font-size-base;
  font-weight: 300;
  max-width: 600px;

  .optionsContainer {
    box-shadow: inset 0 -10px 4px -10px $colour-primary-grey;
    overflow-y: scroll;
    padding: $margin-base-vertical;
  }

  @include media-breakpoint-down('xs') {
    top: 0;
  }

  @include media-breakpoint-down('xs') {
    .optionsContainer {
      box-shadow: inset 0 -10px 4px -10px $colour-primary-grey;
      height: 415px;
      overflow: scroll;
      margin: 0;
    }
  }
}

.optionsContainer {
  font-weight: 200;
  line-height: 24px;
  max-height: 600px;

  p {
    padding: 0 $padding-large-horizontal;
  }

  input {
    width: 28px;
  }

  h3 {
    font-size: ceil(($font-waitrose-title-size-small * .8));
    margin-left: $padding-large-horizontal;
    margin-top: 0;
  }
}

.banner {
  cursor: default;

  .optionsContainer {
    p {
      text-align: center;
    }
  }

  @include media-breakpoint-down('xs') {
    .optionsContainer {
      p {
        text-align: left;
      }
    }
  }
}

.cookiesCTA {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: $ingredients-unit * 4;
  padding: $padding-xl-vertical $padding-large-horizontal;
 
  .acceptAll, .rejectAll {
    grid-column: span 1;
    width: 100%;
    min-width: unset;
    max-width: 200px;
  }
   
  .acceptAll {
    margin-left: auto;
  }
  
  .rejectAll {
    margin-right: auto;
  }

  .manageCookies {
    grid-column: span 2;
    margin: auto;
  }

  @include media-breakpoint-up('md') {
    & {
      grid-template-columns: 200px 200px auto;
    }

    .manageCookies {
      grid-column: span 1;
      margin: 0;
    }
  }
}

.radioContainer {
  @include media-breakpoint-down('md') {
    margin: $padding-xl-vertical;
    margin-left: 8px;
  }
  justify-content: left;
  margin-left: 8px;
}

.fullModalAcceptCTA {
  padding: $padding-xl-vertical $padding-large-horizontal;
  display: flex;
  justify-content: center;
}