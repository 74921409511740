@import '../../ingredients/styles/focus';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

.wrapper {
  align-items: center;
  display: flex;
  justify-content: left;
  line-height: 0;
  margin-left: 0;

  @include media-breakpoint-up('md') {
    flex: 0 1 100%;
    width: auto;
  }

  &.minimal {
    flex: 0 1 100%;
    justify-content: center;
    margin: 40px 0;
    width: auto;

    @include media-breakpoint-up('lg') {
      margin: 30px 0;
    }
  }
}

.link {
  @include focus;
}
