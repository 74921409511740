@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '../../../../ingredients/styles/focus';

.closeButton {
  $inset: 12px;
  $size: 30px;
  @include focus;
  @include pseudo-cross($length: 15px, $thickness: 1px, $colour: $colour-primary-grey);

  background-color: $colour-transparent;
  border: 0;
  cursor: pointer;
  height: $size;
  position: absolute;
  right: $inset;
  top: $inset;
  width: $size;
  z-index: 1;

  &.fullScreen {
    // Bigger style for full screen modals
    @include pseudo-cross($length: 24px, $thickness: 1px, $colour: $colour-primary-grey);
  }
}

.disabled {
  cursor: not-allowed;
  opacity: .3;
}
