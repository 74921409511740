@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

$header-link-margin: 22px;

%header-icon {
  margin-bottom: 4px;
  width: 24px;
}

%header-links {
  font: {
    family: $font-family-base;
    size: 11px;
  }
  letter-spacing: 1.8px;
  text-transform: uppercase;

  @include media-breakpoint-up('md') {
    font-size: 12px;
    letter-spacing: 2px;
  }

  a {
    color: $colour-earl-grey;
  }
}
