@import '../../ingredients/styles/spacing';

.refreshMessaging {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ($ingredients-gutter * 3) 0;
}

.refreshButton {
  margin-top: $ingredients-gutter;
}
