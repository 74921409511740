@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';

.share {
  max-width: 560px;
}

.copy {
  display: flex;
  align-items: stretch;
  height: 40px;
  margin: 28px 0 40px;

  input, input:focus-visible {
    appearance: none;
    width: 332px;
    border: solid 1px;
    border-radius: 0;
    padding-left: 9px;
    color: $colour-primary-grey;
    font-weight: 200;
    outline: 0;
  }

  button {
    width: 148px;
  }
}

