@import '../FormFields';

.subText {
  font: {
    family: Waitrose;
    size: 14px;
    weight: 200;
  }
  color: $colour-waitrose-grey;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 1.42857143; // 20px
  background: $colour-white;

  &.inlineInputs {
    text-align: center;
  }
}

.helpText {
  flex-grow: 1;
  padding: 8px 12px;
  width: 100%;

  & > a {
    font-weight: 400;
    text-decoration: underline;
  }
}

.errorMsg {
  color: $colour-error;
  position: relative;
  padding: 8px 12px;
  flex-grow: 1;
  width: 100%;

  .inlineInputs & {
    display: inline-block;
  }
}

.characterCount {
  flex-grow: 0;
  margin: {
    bottom: auto;
    left: $margin-bottom * 0.5;
  }
  text-align: right;
  transition: color $transition-timing-fast;
}

.nearMax {
  color: $colour-error;
}

.whyButton {
  border: 0;
  color: $link-color;
  padding: 0 6px 0 0;
  text-decoration: underline;

  &,
  &:active,
  &[disabled] {
    background-color: $colour-transparent;
    box-shadow: none;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    border-color: $colour-transparent;
  }

  &:hover,
  &:focus {
    background-color: $colour-transparent;
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

@keyframes characterCountPulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.333333);
  }

  100% {
    transform: scale(1);
  }
}

.reachedMax {
  animation: {
    duration: $transition-timing-slow;
    iteration-count: 1;
    name: characterCountPulse;
    timing-function: ease;
  }
}
