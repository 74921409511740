@import '@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import '@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';

.filters {
  background-color: $colour-white;
  height: min-content;
  padding: 32px 16px 32px 32px;

  button {
    @include focus;
  }

  h2 {
    padding: 8px 0 16px;
    margin: 0;
    border-bottom: 1px solid $colour-mushroom-grey;
    font-weight: 500;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h3 {
    text-transform: capitalize;
  }

  li {
    & :first-child {
      label {
        margin-top: 8px;
      }
    }

    label {
      align-items: flex-start;
    }
  }

  @include media-breakpoint-down('md') {
    background-color: $colour-eggshell-grey;
    padding: 20px 16px 0;
  }
}

.white {
  background-color: $colour-white;
}

.accordion {
  margin-left: -20px;

  hr {
    margin-left: 20px;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
  }

  button {
    padding-top: 7.5px;
  }

  @include media-breakpoint-up('lg') {
    button {
      margin-left: 8px;
    }
  }

  > div {
    margin-top: -6px;
    margin-left: 12px;

    > ul {
      margin-top: 8px;
    }

    > div {
      margin-bottom: 16px;
      margin-top: 0;
    }
  }

  ul > li {
    margin: 6px 0 6px 8px;
  }

  // Override styling of SelectionControl. To be removed later.
  // Note that the label contents are all aligned to the top to handle the wrapping case
  [type='checkbox'] {
    + label {
      margin-bottom: 8px;

      &::before {
        top: 0;
        transform: scale(0.8333) !important;
      }

      &::after {
        top: 12px !important;
        transform: translateY(-4px) rotate(-50deg) scale(0.93) !important;
      }

      p {
        position: relative;
        top: 1px;
      }
    }
  }
}

.applied {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;

  button {
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
  }

  p {
    margin: 0;
  }
}

.removeIcon {
  display: block;
  margin: 4px;
}

.chevronIcon {
  display: block;
  margin: 6px;
}

.appliedHeader {
  h3 {
    text-transform: uppercase;
    padding-top: 8px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  ul {
    border-bottom: 1px solid $colour-mushroom-grey;
  }
}

.clear {
  margin-bottom: 16px;

  p {
    margin: 0;
  }
}

.content {
  background-color: transparent;
}

@keyframes slideUp {
  0% {
    transform: translateY(600px);
  }

  100% {
    transform: translateY(0);
  }
}

.filterButton {
  justify-content: space-between;
}

.overlay {
  display: block !important;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: scroll;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  animation: slideUp 0.5s ease;
  z-index: 1050;

  & .filterButton {
    display: none;
  }

  & .mobileBtns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    padding: 20px;
    @include media-breakpoint-down('xs') {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }

  > :first-child {
    border-bottom: 1px solid $colour-mushroom-grey;
    display: flex;
    justify-content: center;
    padding: 20.8px 0;

    h2 {
      border: none;
      text-transform: none;
      padding: 0;
      flex-grow: 1;
      text-align: center;
    }
  }

  h3 {
    margin-left: 32px;
  }

  ul {
    margin: 16px 32px;
    @include media-breakpoint-down('md') {
      margin-left: 40px;
    }
  }

  i {
    display: none;
  }

  .accordion {
    > div {
      &:first-child {
        margin-left: 20px;
        margin-right: 8px;
      }

      > button {
        border-bottom: 1px solid $colour-mushroom-grey;
        padding: 0;
        padding-left: 8px;
        margin-left: 8px;

        > div {
          padding-left: 0;

            &:last-child {
              margin-right: 8px;
          }
        }
      }
    }
  }
}

.showMore {
  margin-bottom: 16px;

  @include media-breakpoint-down('md') {
    margin-left: 40px;
  }
}

.showMore,
.clear {
  border: none;
  cursor: pointer;
  background-color: transparent;
  text-decoration: underline;
  padding: 0;
}

.desktop {
  @include media-breakpoint-down('md') {
    display: none;
  }
}

.mobile {
  display: none;
  @include media-breakpoint-down('md') {
    display: block;
  }
}

.inner {
  display: block;

  @include media-breakpoint-down('md') {
    display: none;
  }
}

.innerOpen {
  display: block;
}

.divider {
  @include media-breakpoint-down('md') {
    hr {
      border-top: solid 36px $colour-scallop-grey;
    }
  }
}

.hidden {
  display: none;
}

.modalOverlay {
  z-index: 2;
  background-color: $colour-primary-grey--alpha-70;
}
