//
// Utility classes
// --------------------------------------------------


// Floats
// -------------------------

.clearfix {
  @include clearfix;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}


// Simple flex columns
// -------------------------

.flex-cols-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.align-bottom {
    align-items: flex-end;
  }

  &.align-top {
    align-items: flex-start;
  }
}

// Simple flex rows
// -------------------------

.flex-row {
  display: flex;
  flex-direction: row;

  &.space-between {
    justify-content: space-between;
  }
}

// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}


// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
}

// Remove click events from interactive components
// -------------------------

.noClick {
  pointer-events: none;
}

