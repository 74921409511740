@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '../wdx/common';
@import './constants';

%button {
  $fast: $wdx-transition-time-fast;

  background-image: none;
  border: 0;
  border-radius: $button-border-radius;
  display: inline-block;
  cursor: pointer;
  font: {
    family: Waitrose;
    size: $font-size-large;
    weight: 400;
  }
  line-height: $line-height-base;
  padding: $padding-base-vertical $padding-base-horizontal;
  text-align: center;
  touch-action: manipulation;
  transition: background-color $fast, border-color $fast, color $fast;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &,
  &:active {
    &:focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:active {
    background-image: none;
    outline: 0;
  }

  &[disabled] {
    box-shadow: none;
    pointer-events: none;

    &,
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      background: $colour-mushroom-grey;
      color: $colour-chia-grey;
      cursor: auto;
      opacity: 1;
    }
  }
}

%text-button {
  @extend %button;
  border-radius: 0;
  color: $link-color;
  font-weight: normal;
  margin-top: 0;

  &,
  &:active,
  &[disabled] {
    background-color: $colour-transparent;
    box-shadow: none;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    border-color: $colour-transparent;
  }

  &:hover,
  &:focus {
    background-color: $colour-transparent;
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &[disabled] {
    &:hover,
    &:focus {
      color: $colour-mushroom-grey;
      text-decoration: none;
    }
  }
}

// Block button
// --------------------------------------------------

.blockButton {
  width: 100%;
}

// Vertically space out multiple block buttons
.blockButton + .blockButton {
  margin-top: 5px;
}

// original: /src/styleguide/components/_button.scss

.primary {
  @extend %button;

  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    background: $colour-waitrose-green;
    color: $colour-white;
  }

  &:hover,
  &:focus {
    background: $colour-hover-green;
  }

  &:active {
    // TODO: This colour is not allowed here.
    //  Instead of replacing it with a different one,
    //  we recommend replacing the whole Button component with the one from Ingredients.
    // stylelint-disable-next-line @johnlewispartnership/eslint-plugin-wtr-ingredients/no-colour-literals
    background: #456012;
  }
}

.secondary {
  @extend %button;

  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    background: $colour-white;
    border: 1px solid $colour-waitrose-green;
    color: $colour-waitrose-green;
  }

  &:hover,
  &:focus {
    background: $colour-scallop-grey;
  }

  &:active {
    // TODO: This colour is not allowed here.
    //  Instead of replacing it with a different one,
    //  we recommend replacing the whole Button component with the one from Ingredients.
    // stylelint-disable-next-line @johnlewispartnership/eslint-plugin-wtr-ingredients/no-colour-literals
    background: #dee5d2;
  }
}

.textButton {
  @extend %text-button;
}

%small-text-base-style {
  background: none;
  border: 0;
  color: $colour-primary-grey;
  font-size: $font-size-small;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  vertical-align: unset;
  white-space: nowrap;
}

.smallTextButton {
  @extend %small-text-base-style;

  &:hover,
  &:visited,
  &:active {
    @extend %small-text-base-style;
  }

  &:focus {
    @extend %small-text-base-style;
    outline: 1px dotted $colour-primary-grey;
    outline-offset: -1px;
  }
}

%text-base-style {
  background: none;
  border: 0;
  color: $colour-primary-grey;
  margin: 0;
  vertical-align: unset;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
}

.headerLink {
  @extend %text-base-style;
  letter-spacing: 2px;

  &:hover,
  &:visited,
  &:active {
    @extend %text-base-style;
    text-decoration: underline;
  }

  &:focus {
    @extend %text-base-style;
    outline: 1px dotted $colour-primary-grey;
    outline-offset: -1px;
  }
}

// removes styles from element for use in scemantically rather then styling.
.noStyle {
  border-style: none;
  padding: 0;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  background-color: transparent;
}
