@import '@johnlewispartnership/wtr-ingredients/foundations/colours';

// Popovers
// --------------------------------------------------
.Popover {
  // this is required (for now) for the my waitrose popover
  z-index: 1;
  transform: translateY(0) !important;
  transition-duration: 0ms;
  transition-property: transform !important;

  .Popover-tip {
    transition: transform 0ms ease-in !important;
  }
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  display: none;
  max-width: 170px;
  padding: 1px;

  /*
   * Our parent element can be arbitrary since popovers are by default inserted as a sibling of their target element.
   * so reset our font and text properties to avoid inheriting weird values.
   */
  font: {
    family: $font-family-base;
    size: $font-body-size-very-small;
    style: normal;
    weight: normal;
  }
  letter-spacing: normal;
  line: {
    break: auto;
    height: $line-height-base;
  }
  text: {
    align: left; // Fallback for where `start` is not supported
    align: flex-start;
    decoration: none;
    shadow: none;
    transform: none;
  }
  white-space: normal;
  word: {
    break: normal;
    spacing: normal;
    wrap: normal;
  }

  background-color: $colour-scallop-grey;
  background-clip: padding-box;
  border: 1px solid $popover-fallback-border-color;
  border: 1px solid $popover-border-color;
  border-radius: 0;
  box-shadow: 0 5px 10px $colour-primary-grey--alpha-40;

  filter: drop-shadow(0 3px 7px $colour-primary-grey--alpha-40);
  color: $colour-waitrose-grey;
  border-color: $colour-scallop-grey;
  animation: fadeIn .4s;

  // Arrows
  // .arrow is outer, .arrow:after is inner

  > .arrow {
    border-width: $popover-arrow-outer-width;

    &,
    &::after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: $colour-transparent;
      border-style: solid;
    }

    ::after {
      border-width: $popover-arrow-width;
      content: '';
    }
  }

  // Offset the popover to account for the popover arrow
  &.top {
    margin-top: -$popover-arrow-width;

    > .arrow {
      left: 50%;
      margin-left: -$popover-arrow-outer-width;
      border-bottom-width: 0;
      border-top-color: $colour-white;
      bottom: -$popover-arrow-outer-width;
      margin-right: 1px;

      &::after {
        content: ' ';
        bottom: 1px;
        margin-left: -$popover-arrow-width;
        border-bottom-width: 0;
        border-top-color: $colour-scallop-grey;
        margin-right: 1px;
      }
    }
  }

  &.right {
    margin-left: $popover-arrow-width;

    > .arrow {
      top: 50%;
      left: -$popover-arrow-outer-width;
      margin-top: -$popover-arrow-outer-width;
      border-left-width: 0;
      border-right-color: $colour-white;

      &::after {
        content: ' ';
        left: 1px;
        bottom: -$popover-arrow-width;
        border-left-width: 0;
        border-right-color: $popover-arrow-color;
      }
    }
  }

  &.bottom {
    margin-top: $popover-arrow-width;

    > .arrow {
      left: 50%;
      margin-left: -$popover-arrow-outer-width;
      border-top-width: 0;
      border-bottom-color: $colour-white;
      top: -$popover-arrow-outer-width;
      margin-right: 1px;

      &::after {
        content: ' ';
        top: 1px;
        margin-left: -$popover-arrow-width;
        border-top-width: 0;
        border-bottom-color: $colour-scallop-grey;
        margin-right: 1px;
      }
    }
  }

  &.left {
    margin-left: -$popover-arrow-width;

    > .arrow {
      top: 50%;
      right: -$popover-arrow-outer-width;
      margin-top: -$popover-arrow-outer-width;
      border-right-width: 0;
      border-left-color: $colour-white;
      margin-right: 1px;

      &::after {
        content: ' ';
        right: 1px;
        border-right-width: 0;
        border-left-color: $colour-scallop-grey;
        bottom: -$popover-arrow-width;
        margin-right: 1px;
      }
    }
  }
}

.popover-title {
  margin: 0; // reset heading margin
  padding: 8px 14px;
  font-size: $font-size-base;
  background-color: $popover-title-bg;
  border-bottom: 1px solid $colour-mushroom-grey;
  border-radius: ($border-radius-large - 1) ($border-radius-large - 1) 0 0;
}

.popover-content {
  padding: 9px 14px;
}
