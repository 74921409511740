@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';

.h1 {
  font-size: 20px;
  line-height: 28px;

  @include media-breakpoint-up('sm') {
    font-size: 28px;
    line-height: 36px;
  }

  @include media-breakpoint-up('lg') {
    font-size: 36px;
    line-height: 44px;
  }
}