@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../mixins';

.outOfStock {
  @include media-breakpoint-down('xs') {
    padding: 10px 20px;
    margin-top: inherit;
  }

  @include trolley-control-conflict;

  width: 100%;
  padding: 19px 20px;
  margin-top: -8px;
  border-top: 1px solid $colour-oyster-grey;
  font-weight: 700;
  font-size: $font-waitrose-body-size-small * .875; // 14px
  text-align: center;
  background: $colour-mushroom-grey;

  [class^='conflictWrapper'] & {
    span {
      display: block;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

}

.onPDP {
  display: inline-block;
  width: auto;
  padding: 14px 0;
  margin-top: inherit;
  border-top: 0;
  font-size: $font-waitrose-body-size-medium; // 18px
  background: none;

  @include media-breakpoint-up('sm') {
    float: none;
    padding: 10px 0 20px;
    text-align: left;
  }
}