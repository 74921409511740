@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';

.nutritional {
  margin-top: 40px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 11px 0 0;
      border-bottom: 1px solid $colour-mushroom-grey;
    }
  }

  .cell_right {
    text-align: right;
  }
}
