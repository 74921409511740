@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../FormFields';

$colour-label-disabled: $colour-chia-grey;
$font-size-label-optional: 16px;

.label {
  font: {
    family: Waitrose;
    size: $form-font-size;
    weight: 400;
  }
  text-align: left;
}

.disabled {
  color: $colour-label-disabled !important;
  cursor: not-allowed;
}
