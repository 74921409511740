@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../ingredients/styles/focus';
@import '../../../styles/settings/variables';
@import '../common';

.forwardIcon {
  width: $menu-icon-width;
  display: inline-flex;
  position: absolute;
  right: $forward-icon-inset;
}

.loading {
  display: block;
  padding: 7px 20px;
}

.mLnk {
  align-items: center;
  color: $text-color;
  display: flex;
  height: $menu-item-height;
  padding: {
    left: $menu-item-padding-left-desktop;
    right: $menu-item-padding-right;
  }
  position: relative;

  &:hover {
    @include menu-item-hover;
  }

  &:focus {
    @include focus-outline-inset;
    text-decoration: none;
  }

  &.active {
    background-color: $colour-waitrose-grey;
    color: $colour-white;

    &:hover,
    &:focus {
      background-color: $colour-waitrose-grey;
      color: $colour-white;
    }
  }

  &.more {
    text-decoration: none;
  }

  &.favourites {
    &:hover {
      path {
        stroke: $colour-white;
      }
    }
  }

  &:global(.offerLink) {
    color: $colour-offer-red;
    font: {
      size: 16px;
      weight: 700;
    }
    text-transform: uppercase;

    &:hover {
      background-color: $colour-offer-red;
      color: $colour-white;
    }
  }
}

.more {
  padding-right: ($horizontal-spacing + 17px);
}

.offers {
  color: $colour-offer-red;

  &:focus {
    color: $colour-offer-red;
  }

  &:hover {
    background-color: $colour-offer-red;
    color: $colour-white;
  }
}

.text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
