@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/extends';

%conflict {
  @extend %focus-first-child;
  appearance: none;
  background-color: $colour-transparent;
  border: 0;
  padding: 0;
  text-align: left;
}

.conflict {
  @extend %conflict;
  width: 100%;
}

.pdpConflict {
  @extend %conflict;
  clear: both;
  margin-bottom: $grid-vertical-gutter * 1.25;
}
