@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';

.modalContent {
  text-align: left;
}

.stepsHeading {
  margin-bottom: 40px;
}

.step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.iconContainer {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border: 1px solid $colour-oyster-grey;
  width: 32px;
  height: 32px;
}

.icon {
  width: 20px;
  height: 20px;
  background: {
    position: center;
    repeat: no-repeat;
    size: contain;
  }
}

.chefIcon {
  background: {
    image: url('../icons/background-svg/chef.svg');
  }
}

.entertainingIcon {
  background: {
    image: url('../icons/background-svg/entertaining.svg');
  }
}

.trolleyInactiveIcon {
  background: {
    image: url('../icons/background-svg/trolley-inactive.svg');
  }
}

.divider {
  border: none;
  border-top: 1px solid $colour-oyster-grey;
  margin: 40px 0;
}

.informationIcon {
  background: {
    position: left center;
    repeat: no-repeat;
    size: 16px 16px;
  }
  padding-left: 32px;
}

.swapIcon {
  background-image: url('../icons/background-svg/swap-black.svg');
}

.removeIcon {
  background-image: url('../icons/background-svg/close-dark.svg');
}

.settingsIcon {
  background-image: url('../icons/background-svg/settings.svg');
}