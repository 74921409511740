.wrapper {
  padding: 4px 0;
  position: relative;
}

.slider {
  @mixin enable-scroll {
    overflow-x: auto;
  }

  display: flex;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;

  @media (pointer: coarse) {
    -webkit-overflow-scrolling: touch;
  }

  @media (hover: none) {
    @include enable-scroll;
  }

  &:hover {
    @include enable-scroll;
  }

  &.slide {
    justify-content: flex-start;
  }
}
