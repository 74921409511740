@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

/* Mobile first */
.checkoutWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body.webview {
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: $colour-transparent;
}

.background {
  background-color: $colour-scallop-grey;
  flex-grow: 1;
}

.loadingIndicator {
  position: fixed;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pageTitle {
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    margin-top: 0;
  }

  &::after {
    border-bottom: {
      width: 2px;
      style: solid;
      color: $colour-primary-grey;
    }

    content: ' ';
    display: block;
    margin: 6px auto 12px;
    width: 70px;

    .hasSubcategories & {
      border-bottom: 0;
    }
  }

  @include media-breakpoint-down('sm') {
    padding-bottom: 0;
  }
}

.container {
  padding-inline: 16px;
  padding-bottom: 16px;
  display: grid;
  grid-auto-columns: auto;
  gap: 32px 16px;
  grid-template-areas:
    'mobileOrderSummary'
    'slotDetails'
    'paymentDetails'
    'voucherPromos'
    'orderSummary';
}

.resolveOrderPaymentContainer {
  @extend .container;

  grid-template-areas:
    'paymentDetails'
    'orderSummary';
}

.slotDetailsArea {
  grid-area: slotDetails;
}

.orderSummaryArea {
  grid-area: orderSummary;
}

.mobileOrderSummaryArea {
  grid-area: mobileOrderSummary;
}

.paymentDetailsArea {
  grid-area: paymentDetails;
}

.voucherPromosArea {
  grid-area: voucherPromos;
}

@include media-breakpoint-up('md') {
  .container {
    padding-bottom: 120px;
    min-height: 100vh;
    max-width: 1024px;
    margin: 0 auto;

    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: min-content min-content min-content;
    gap: 32px 16px;
    grid-template-areas:
      'slotDetails orderSummary'
      'paymentDetails orderSummary'
      'voucherPromos orderSummary';
  }

  .resolveOrderPaymentContainer {
    grid-template-areas: 'paymentDetails orderSummary';
  }

  .mobileOrderSummaryArea {
    display: none !important;
  }
}
