@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../mixins';

$conflict-padding-left-right: $grid-vertical-gutter * 1.25;
$conflict-padding-top-bottom: $conflict-padding-left-right * .5;
$resolution-padding-left-right: $grid-vertical-gutter * .5;

.conflict {
  background: $colour-waitrose-conflict-background;
  border-top: 1px solid $colour-oyster-grey;
  display: table;
  font-size: $font-waitrose-body-size-small * .875; // 14px
  padding: $conflict-padding-top-bottom $conflict-padding-left-right;
  width: 100%;

  @include media-breakpoint-up('sm') {
    margin-top: $grid-vertical-gutter * -.5;
  }

  &.onPDP {
    width: auto;
    margin-top: 0;
    font-size: $font-waitrose-body-size-small; // 16px
    border: 0;
    clear: both;

    @include media-breakpoint-down('xs') {
      width: 100%;
    }
  }

  @include trolley-control-conflict;
}

.icon {
  padding-right: $resolution-padding-left-right;
  vertical-align: top;

  svg {
    display: block;
  }
}

.icon,
.content {
  display: table-cell;
}

.message,
.resolution {
  display: inline;
  text-align: left;
}

.message {
  padding-right: $resolution-padding-left-right;
  margin: 0;
  font-weight: 200;
}

.onPDP {
  border-top: 0;
}

.resolution {
  display: inline-block;
}

.textOnly {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @include media-breakpoint-up('sm') {
    min-height: 55px;

    [class^='conflictWrapper'] & {
      min-height: initial;
    }
  }

  .content,
  .message,
  .resolution {
    padding: 0;
    text-align: center;
    display: inline-block;

    [class^='conflictWrapper'] & {
      display: block;
      text-align: inherit;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  .resolution {
    padding: 0;

    [class^='conflictWrapper'] & {
      padding: 0;
    }
  }

  .hasResolution & {
    padding: {
      top: $conflict-padding-top-bottom;
      right: ($conflict-padding-left-right - $resolution-padding-left-right);
      bottom: $conflict-padding-top-bottom;
      left: $conflict-padding-left-right;
    }

    [class^='conflictWrapper'] & {
      padding: 0;
    }
  }

}
