@import '../FormFields';
@import '../../../../styles/tools/extends';

.container {
  @extend %container;

  + .container {
    margin-top: -$margin-bottom + 20px;
  }
}

.radioContainer {
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  color: $text-color;
  cursor: pointer;
  display: inline-block;
  font-weight: 200;
  margin: 0;
}

.checkbox {
  @include checkbox-control;
}

.radio {
  @include radio-control($radio-vertical-padding: 8px);
}

.input {
  &:focus + label {
    &::before {
      @extend %browser-initial-focus;
    }
  }
}
