@mixin pseudo-cross($length, $thickness, $colour) {
  $half-length: $length * .5;
  $half-thickness: $thickness * .5;

  &:before,
  &:after {
    border-left: $thickness solid $colour;
    content: '';
    display: block;
    height: $length;
    left: calc(50% - #{$half-thickness});
    position: absolute;
    top: calc(50% - #{$half-length});
    width: 1px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
