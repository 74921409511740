@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '../wdx/animation';

.closeOnClick {
  cursor: pointer;
}

.modalOpen {
  overflow: hidden;

  .fullScreenTransitions {
    transform: translateY(0);
  }
}

.overlay {
  align-items: center;
  background-color: $colour-primary-grey--alpha-40;
  bottom: 0;
  display: flex;
  flex: {
    direction: row;
    wrap: nowrap;
  }
  left: 0;
  overflow: {
    x: hidden;
    y: auto;
  }
  pointer-events: auto;
  position: fixed;
  right: 0;
  top: 0;
  transition: {
    property: opacity;
    timing-function: ease-in;
  }
  z-index: $zindex-modal-background;
}

.bottom {
  align-items: flex-end;

  .container {
    flex-grow: 1;
  }

  @include media-breakpoint-up('md') {
    align-items: center;

    .container {
      flex-grow: 0;
    }
  }
}

%container-default {
  background-color: $colour-white;
  box-shadow: 0 3px 7px 0 $colour-primary-grey--alpha-40;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.container {
  @extend %container-default;
  overflow: auto;
}

.containerNoOverflow {
  @extend %container-default;
  overflow: hidden;
}

.fullHeightSmall {
  height: 100%;

  @include media-breakpoint-up('md') {
    height: auto;
  }
}

.fullScreenTransitions {
  height: 100%;
  transform: translateY(100%);
  transition: transform $wdx-transition-time-fast ease;
  width: 100%;

  .container {
    height: 100%;
    width: 100%;
  }
}

.spaceAroundLarge {
  @include media-breakpoint-up('md') {
    .container {
      max-width: max-content;
      width: 90%;
    }
  }
}

.whiteOverlay {
  background-color: $colour-white--alpha-90;

  .container {
    background-color: transparent;
  }

  &.bottom {
    .container {
      background-color: $colour-white;
    }
  }
}
