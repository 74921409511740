@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../settings/variables';

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed; // iOS fix as it doesn't work with overflow: hidden
  width: 100%;

  #content {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  &.mobileFiltersModal #content {
    overflow: auto;
  }
}

.ReactModal__Overlay {
  z-index: $zindex-modal;
  background-color: $colour-primary-grey--alpha-70;
}
