@import '@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins";
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables";

.bookSlot {
  margin-bottom: 20px;

  .bookSlotBtn {
    padding: 2px 7px;
  }
}

.text {
  margin-left: 20px;

  @include media-breakpoint-down('md') {
    max-width: unquote('calc(100% - 58px)'); /* stylelint-disable-line scss/no-global-function-names */
  }
}

.sticky {
  position: fixed;
  bottom: -220px;
  left: 0;
  width: 100%;
  z-index: 3;
  background-color: $colour-white;
  box-shadow: 0 -4px 4px 0 rgba($colour-primary-grey, 0.1); /* stylelint-disable-line @johnlewispartnership/eslint-plugin-wtr-ingredients/no-colour-literals */
  transition: bottom .3s ease-in;
  height: 80px;
  display: flex;
  align-items: center;

  @include media-breakpoint-up('md') {
    bottom: -160px;
  }
}

.staticBanner {
  margin-bottom: 36px;

  @include media-breakpoint-up('sm') {
    margin-bottom: 60px;
  }
}

.shopBanner {
  display: flex;
  justify-content: center;
  gap: 8px;

  @include media-breakpoint-up('md') {
    gap: 12px;
  }
}

.hideBtnIcon {
  // Workaround to hide button leading icon 424px down
  @media (max-width: 424px) {
    button > svg, a > svg {
      display: none;
    }

    button > span, a > span {
      padding-left: 0 !important;
    }
  }
}

.showShopCard {
  bottom: 0;
}

.modal {
  border-top: solid 4px $colour-waitrose-green;
  width: 100%;
  height: 100%;
  max-width: unquote('min(800px, max(768px, 80.5vw))');
  max-height: 100vh;

  @include media-breakpoint-up('md') {
    position: fixed;
    max-height: 95vh;
  }

  header {
    min-height: 28px;
    border: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0;

    > div {
      display: none;
    }

    svg {
      width: 11px;
      height: 11px;
      margin-top: -10px !important;
    }
  }

}

.modal-content {
  text-align: left;
  padding: 0;

  h2 {
    margin: 0 20px 20px;

    @include media-breakpoint-up('md') {
      margin-left: 32px;
    }

    > span {
      display: block;
      margin-bottom: 4px;
    }
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include media-breakpoint-down('md') {
    height: 100%;
  }

  @include media-breakpoint-up('md') {
    justify-content: flex-start;
    margin-top: 100px;
  }
}

.successWrapper {
  @extend .loadingWrapper;

  .successIcon {
    color: $colour-success-green;
  }

  .warningIcon {
    color: $colour-error-red;
  }
}

.modalHeader {
  position: sticky;
  top: 6px;
  width: 100%;
  background-color: $colour-white;
  z-index: 2;
}

.servingInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $colour-waitrose-grey;
  height: 48px;

  .serves, .selected {
    color: $colour-white;
    padding: 12px 20px;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  box-shadow: 0 -4px 4px 0 $colour-primary-grey--alpha-40;
  width: 100%;
  height: 80px;
  background-color: $colour-white;
  padding: 20px 16px;

  @include media-breakpoint-up('md') {
    padding: 20px;
    justify-content: flex-end;
  }

  .button {
    @include media-breakpoint-down('sm') {
      width: 100%;
    }
  }
}

.servingInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $colour-waitrose-grey;
  height: 48px;

  .serves, .selected {
    color: $colour-white;
    padding: 12px 20px;
  }
}

.alerts {
  padding: 16px 16px 0;
  margin-bottom: 8px;

  @include media-breakpoint-up('md') {
    padding: 20px 20px 0;
  }

  .trolleyConflict {
    margin-top: 12px;
  }
}