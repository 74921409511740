@import '../../styles/settings/variables';

.legend {
  color: $text-color;
  font-weight: 200;
}

.wrapper {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  align-items: center;
  display: flex;
  flex: 1 0 auto;
}
